import React, { useState, useEffect } from "react";
import { Input, Modal, Select, Space, Switch } from "antd";
import { TiLocationArrow } from "react-icons/ti";
import { HiDeviceMobile } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import { BsFillGridFill } from "react-icons/bs";
import { AiOutlineUnorderedList, AiOutlineFieldNumber } from "react-icons/ai";
import { FORMAT_DATE, list_district } from "../utils/const";
import CustomList from "./components/list";
import Grid from "./components/grid";
import { CgSmartphoneRam } from "react-icons/cg";
import { RiRouterFill } from "react-icons/ri";
import { CiCalendarDate } from "react-icons/ci";
import { BsCpu } from "react-icons/bs";
import { FaTemperatureLow } from "react-icons/fa";

import Map from "./components/map";
import {
  MdSignalWifiStatusbarNotConnected,
  MdWifi,
  MdWifiOff,
} from "react-icons/md";
import { Link } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../utils/provider";
import { socket } from "../utils/socket";
import { mainApi } from "../utils/api";
const columns = [
  {
    icon: <HiDeviceMobile size={20} color="#9291A5" />,
    title: "Нэр",
    field: "manager.name",
    width: 200,
  },
  {
    icon: <BsCpu size={15} color="#9291A5" />,
    title: "Дүүрэг",
    field: "manager.district",
  },
  {
    icon: <TiLocationArrow size={20} color="#9291A5" />,
    title: "Чиглэл",
    field: "manager.direction",
    width: 200,
  },
  {
    icon: <AiOutlineFieldNumber size={20} color="#9291A5" />,
    title: "Систем-н хувилбар",
    field: "version",
    width: 200,
  },

  {
    icon: <CgSmartphoneRam size={15} color="#9291A5" />,
    title: "Runtime /цаг/",
    field: "runtime",
    width: 120,
    render: ({ data }) => {
      const { runtime } = data;

      return <div>{Math.floor(parseInt(runtime / 60, 10) / 60)}</div>;
    },
  },
  {
    icon: <CgSmartphoneRam size={15} color="#9291A5" />,
    title: "Нийт уншсан",
    field: "alltag",
    width: 150,
  },
  {
    icon: <CgSmartphoneRam size={15} color="#9291A5" />,
    title: "Нийт илгээсэн",
    field: "sent",
    width: 150,
  },
  {
    icon: <CgSmartphoneRam size={15} color="#9291A5" />,
    title: "Нийт илгээгээгүй",
    field: "unsent",
    width: 150,
  },
  {
    icon: <CgSmartphoneRam size={15} color="#9291A5" />,
    title: "Ram",
    field: "ram",
  },
  {
    icon: <FaTemperatureLow size={15} color="#9291A5" />,
    title: "Халалт",
    field: "temper",
  },
  {
    icon: <RiRouterFill size={15} color="#9291A5" />,

    title: "Антине",
    field: "act_ant",
    render: ({ data }) => {
      const { act_ant } = data;
      const ant = act_ant?.split(",");
      return (
        <div className="flex flex-row justify-center">
          {ant?.map((row, index) => {
            return row == "1" ? (
              <div className="px-1">
                <MdWifi className="text-active-text" />
              </div>
            ) : (
              <div className="px-1">
                <MdWifiOff className="text-deactive-text" />
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    icon: <CiCalendarDate size={15} color="#9291A5" />,
    title: "Огноо",
    field: "createdAt",
    width: 150,
    render: ({ data }) => {
      const { createdAt } = data;
      return <div>{moment(createdAt).format(FORMAT_DATE)}</div>;
    },
  },

  {
    title: "Төлөв",
    field: "dev_status",
    width: 120,
    render: ({ data }) => {
      const { dev_status } = data;
      return (
        <div
          className={`rounded-full  font-semibold text-sm p-2 text-center ${
            dev_status == "ON"
              ? `text-active-text bg-active-background`
              : ` text-deactive-text bg-deactive-background`
          }`}
        >
          {dev_status == "ON" ? "Идэвхтэй" : "Идэвхгүй"}
        </div>
      );
    },
  },
];
const List = () => {
  // const [tab, settab] = useState(1); //1-list,2-map
  const [type, settype] = useState(1); //1-list,2-grid
  const [loading, setloading] = useState(false);
  const [name, setname] = useState("");
  const [direction, setdirection] = useState("");
  const [district, setdistrict] = useState("");
  const [list, setlist] = useState([]);
  const [total, settotal] = useState(0);
  const [page, setpage] = useState(1);
  const { logout } = useAuth();
  useEffect(() => {
    fetch();
  }, [direction, district, name, page]);

  const fetch = async () => {
    setloading(true);

    try {
      let find = {};
      if (district) find = { ...find, field: "datetime", value: district };
      const result = await mainApi({
        method: "GET",
        url: `/api/v1/rfidstatus?page=${page}`,
        data: {},
      });

      setlist(result?.data?.rows);
      settotal(result?.data?.count);
    } catch (e) {
      // if (e)

      if (e.response.status == 401) logout();
      setloading(false);
    }
    setloading(false);
  };
  return (
    <div className="flex p-4 flex-col">
      <div className="flex flex-row  justify-between">
        <div className="font-semibold text-2xl text-def-text">Төхөөрөмж</div>
        <div className="flex flex-row h-6">
          <div
            // onClick={() => settab(1)}
            className={`px-2 hover:cursor-pointer hover:text-def-primary font-semibold `}
          >
            Жагсаалт
          </div>
          {/* <div
            onClick={() => settab(2)}
            className={`px-2 hover:cursor-pointer hover:text-def-primary font-semibold ${
              tab == 2 &&
              ` text-def-primary border-b-2 -mb-2 border-b-def-primary`
            }`}
          >
            Газрын зураг
          </div> */}
        </div>
      </div>

      <div>
        <div className="flex flex-row py-2.5 border-t-2 border-b-2 border-b-def-background mt-4  justify-between">
          <div className="flex flex-row">
            <div className="flex flex-row px-2.5 items-center">
              <MdLocationOn size={25} color="#E15116" />

              <Select
                placeholder="Байршлаар хайх"
                className="w-40"
                bordered={false}
                onChange={(value) => {
                  setdistrict(value);
                }}
                options={list_district}
              />
            </div>
            <div className="flex flex-row px-2.5 items-center">
              <TiLocationArrow size={30} color="#E15116" />
              <Input
                onChange={(e) => {
                  setdirection(e.target.value);
                }}
                value={direction}
                className="border-none"
                placeholder="Чиглэлээр хайх"
              />
            </div>

            <div className="flex flex-row px-2.5 items-center">
              <HiDeviceMobile size={25} color="#E15116" />
              <Input
                onChange={(e) => {
                  setname(e.target.value);
                }}
                value={name}
                className="border-none"
                placeholder="Нэрээр хайх"
              />
            </div>
          </div>
          <div className="flex flex-row items-center border-2 border-def-background rounded p-2">
            <div className="px-2 border-r-2" onClick={() => settype(1)}>
              <AiOutlineUnorderedList
                size={20}
                className={`hover:text-def-primary hover:cursor-pointer ${
                  type == 1 && `text-def-primary`
                }`}
              />
            </div>
            <div className="px-2" onClick={() => settype(2)}>
              <BsFillGridFill
                size={17}
                className={`hover:text-def-primary hover:cursor-pointer  ${
                  type == 2 && `text-def-primary`
                }`}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center h-full items-center">
            <img src="../magnify.svg" />
          </div>
        ) : type === 1 ? (
          <CustomList
            page={page}
            setpage={setpage}
            total={total}
            list={list}
            columns={columns}
            showPage={true}
            showTotal={true}
          />
        ) : (
          <Grid page={page} setpage={setpage} list={list} total={total} />
        )}
      </div>
    </div>
  );
};

export default List;

import React, { useState, useEffect } from "react";
import { Button, Input, Spin, Form, Typography, Select, List } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useAuth } from "../utils/provider";
import { mainApi } from "../utils/api";

const Register = () => {
  const [loading, setloading] = useState(false);
  const history = useNavigate();
  const { logout } = useAuth();
  const onFinish = async (data) => {
    setloading(true);
    try {
      await mainApi({
        url: "/auth/register",
        method: "POST",
        data: data,
      });
      swal("Мэдэгдэл", "Амжилттай бүртгэгдлээ", "success");
      history(-1);
      setloading(false);
    } catch (e) {
      // if (e)

      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  return (
    <div className="flex flex-col p-2  ">
      <div className="flex justify-between items-center p-3 border-def-primary border-l-8 border-def-blue my-2">
        <div className="text-base font-semibold">Хэрэглэгч бүртгэх</div>
      </div>
      {loading ? (
        <div className="flex justify-center h-full items-center">
          <img src="../../magnify.svg" />
        </div>
      ) : (
        <Form
          layout="vertical"
          initialValues={{
            last_name: "",
            first_name: "",
            email: "",
            password: "",
          }}
          onFinish={onFinish}
        >
          <div className="shadow mt-4 p-4">
            <div className=" flex flex-wrap ">
              <div className="w-1/2 p-2">
                <Form.Item label="Эцэг/эх/-ийн нэр" name="last_name">
                  <Input placeholder="Бичих" required />
                </Form.Item>
                <Form.Item label="Нэр" name="first_name">
                  <Input placeholder="Бичих" required />
                </Form.Item>
              </div>
              <div className="w-1/2 p-2">
                <Form.Item label="Имайл" name="email">
                  <Input placeholder="Бичих" required />
                </Form.Item>

                <Form.Item label="Нууц үг" name="password">
                  <Input placeholder="Бичих" required />
                </Form.Item>
              </div>
            </div>
            <div className="flex  justify-between my-4 items-center">
              <Button
                danger
                icon={<AiOutlineArrowLeft className="ml-2" />}
                size="middle"
                onClick={() => history(-1)}
              />

              <Form.Item>
                <Button
                  danger
                  type="primary"
                  size="middle"
                  htmlType="submit"
                  loading={loading}
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Register;

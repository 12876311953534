import moment from "moment";
import objectPath from "object-path";
import React, { useEffect, useState } from "react";
import { FORMAT_DATE } from "../../utils/const";
import { FcApproval, FcApprove } from "react-icons/fc";
import { useAuth } from "../../utils/provider";
import { getCarInfo, getViolance } from "../../temp";
import { Spin } from "antd";
import { mainApi } from "../../utils/api";
const Item = ({ row, total }) => {
  const antenna_num = objectPath.get(row, "antenna_num");
  const count_num = objectPath.get(row, "count_num");
  const createdAt = objectPath.get(row, "createdAt");
  const data = objectPath.get(row, "data");
  const time = objectPath.get(row, "time");
  const epc_num = objectPath.get(row, "epc_num");
  const tid_num = objectPath.get(row, "tid_num");
  const id = objectPath.get(row, "id");
  const name = objectPath.get(row, "manager.name");
  const direction = objectPath.get(row, "manager.direction");
  const district = objectPath.get(row, "manager.district");
  const rssi_level = objectPath.get(row, "rssi_level");
  const [car, setcar] = useState();
  const [violance, setviolance] = useState();
  const [loading, setloading] = useState(false);
  const [loadingVio, setloadingVio] = useState(false);
  const { logout } = useAuth();
  useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const result = await mainApi({
          url: `api/v1/rfid/rfidtag/${epc_num}`,
          method: "GET",
        });

        setcar(result.data);
        fetchViolation(result?.data?.plateNo);
        setloading(false);
      } catch (e) {
        // if (e)
        if (e.response.status == 401) logout();
        setloading(false);
      }
    })();
  }, []);

  const fetchViolation = async (number) => {
    if (number) {
      setloadingVio(true);
      try {
        const viol = await mainApi({
          url: `http://103.9.90.140:5001/service/violation`,
          method: "POST",
          data: {
            plateNumber: number,
          },
          auth: {
            username: "odt",
            password: "odt123456",
          },
        });

        setviolance(viol.data);
        setloadingVio(false);
      } catch (e) {
        // if (e)
        if (e.response?.status == 401) logout();
        setloadingVio(false);
      }
    } else setviolance(null);
  };
  return (
    <div className="my-3 shadow flex flex-row  ounded p-4 hover:border-def-primary hover:border-2">
      <img
        src="../../car.png"
        width={130}
        height={120}
        className="border-2 rounded p-2"
      />
      <div className="flex flex-row ">
        <div className="flex flex-col w-1/3 px-3">
          <div className="flex flex-row justify-between items-center border-b-2">
            <div className="text-base font-semibold">Төхөөрөмжийн мэдээлэл</div>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="text-sm">EPC</div>
            <div className="text-sm font-semibold">{epc_num}</div>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="text-sm">TID</div>
            <div className="text-sm font-semibold">{tid_num}</div>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="text-sm">Уншигдсан огноо</div>
            <div className="text-sm font-semibold">
              {moment(createdAt).format(FORMAT_DATE)}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="text-sm">Төхөөрөмжийн нэр</div>
            <div className="text-sm font-semibold">{name}</div>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="text-sm">Төхөөрөмжийн чиглэл</div>
            <div className="text-sm font-semibold">{direction}</div>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="text-sm">Антине</div>
            <div className="text-sm font-semibold">{antenna_num}</div>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="text-sm">Уншигдсан тоо</div>
            <div className="text-sm font-semibold">{count_num}</div>
          </div>
          <div className="flex flex-row justify-between items-center mt-2">
            <div className="text-sm">RSSI түвшин</div>
            <div className="text-sm font-semibold">{rssi_level}</div>
          </div>
        </div>
        <div className="flex flex-col w-1/3 px-3">
          <div className="flex flex-row justify-between items-center border-b-2">
            <div className="text-base font-semibold">
              Тээврийн хэрэгслийн мэдээлэл
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center mt-5">
              <Spin />
            </div>
          ) : car === null ? (
            <div className="flex justify-center mt-4 font-bold text-red-500">
              <div>Мэдэээлэл олдсонгүй</div>
            </div>
          ) : (
            <>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Улсын дугаар</div>
                <div className="text-sm font-semibold">{car?.plateNo}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Марк</div>
                <div className="text-sm font-semibold">{car?.markName}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Модел</div>
                <div className="text-sm font-semibold">{car?.modelName}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Зориулалт</div>
                <div className="text-sm font-semibold">{car?.purposeName}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Өнгө</div>
                <div className="text-sm font-semibold">{car?.colorName}</div>
              </div>

              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Загвар</div>
                <div className="text-sm font-semibold">{car?.typeNameMon}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Үйлдвэрлэсэн огноо</div>
                <div className="text-sm font-semibold">{car?.buildYear}</div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col w-1/3 px-3">
          <div className="flex flex-row justify-between items-center border-b-2">
            <div className="text-base font-semibold">Зөрчлийн мэдээлэл</div>
          </div>
          {loadingVio == true ? (
            <div className="flex justify-center items-center mt-5">
              <Spin />
            </div>
          ) : violance == null ? (
            <div className="flex justify-center mt-4 font-bold text-red-500">
              <div>Мэдэээлэл олдсонгүй</div>
            </div>
          ) : (
            <div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Эрэн сурвалжлагдаж байгаа эсэх</div>
                {violance?.asap?.violation ? (
                  <div className="text-xs font-semibold bg-red-500 rounded-full p-1 px-4 text-white">
                    Тийм
                  </div>
                ) : (
                  <div className="text-xs font-semibold bg-green-500 rounded-full p-1 px-4 text-white">
                    Үгүй
                  </div>
                )}
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">
                  Согтуу жолоочын мэдээлэл байгаа эсэх
                </div>
                {violance?.drunk?.violation ? (
                  <div className="text-xs font-semibold bg-red-500 rounded-full p-1 px-4 text-white">
                    Тийм
                  </div>
                ) : (
                  <div className="text-xs font-semibold bg-green-500 rounded-full p-1 px-4 text-white">
                    Үгүй
                  </div>
                )}
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Оношилгооны зөрчилтэй эсэх</div>
                {violance?.inspection?.violation ? (
                  <div className="text-xs font-semibold bg-red-500 rounded-full p-1 px-4 text-white">
                    Тийм
                  </div>
                ) : (
                  <div className="text-xs font-semibold bg-green-500 rounded-full p-1 px-4 text-white">
                    Үгүй
                  </div>
                )}
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Даатгалын зөрчилтэй эсэх</div>
                {violance?.insurance?.violation == true ? (
                  <div className="text-xs font-semibold bg-red-500 rounded-full p-1 px-4 text-white">
                    Тийм
                  </div>
                ) : (
                  <div className="text-xs font-semibold bg-green-500 rounded-full p-1 px-4 text-white">
                    Үгүй
                  </div>
                )}
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Татварын зөрчилтэй эсэх</div>
                {violance?.tax?.violation ? (
                  <div className="text-xs font-semibold bg-red-500 rounded-full p-1 px-4 text-white">
                    Тийм
                  </div>
                ) : (
                  <div className="text-xs font-semibold bg-green-500 rounded-full p-1 px-4 text-white">
                    Үгүй
                  </div>
                )}
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Торгуулийн зөрчилтэй эсэх</div>
                {violance?.penalty?.violation ? (
                  <div className="text-xs font-semibold bg-red-500 rounded-full p-1 px-4 text-white">
                    Тийм
                  </div>
                ) : (
                  <div className="text-xs font-semibold bg-green-500 rounded-full p-1 px-4 text-white">
                    Үгүй
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;

import React, { useState, useEffect } from "react";
import { Button, Input, Spin, Form, Typography, Select, List } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { list_district } from "../utils/const";
import swal from "sweetalert";
import { useAuth } from "../utils/provider";
import { mainApi } from "../utils/api";
const Index = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState();
  const { logout } = useAuth();
  useEffect(() => {
    id && fetch();
  }, []);

  const fetch = async () => {
    setloading(true);
    try {
      let res = await mainApi({
        url: `/api/v1/manager/${id}`,
        method: "GET",
      });

      setdata(res.data);

      setloading(false);
    } catch (e) {
      // if (e)

      if (e.response.status == 401) logout();
      setloading(false);
    }
  };

  const onFinish = async ({
    coordinate,
    direction,
    district,
    horoo,
    limit_ant,
    limit_epc_array,
    limit_power,
    limit_save,
    limit_tag,
    name,
    limit_minute,
  }) => {
    setloading(true);
    try {
      await mainApi({
        method: "PUT",
        url: `/api/v1/manager/${id}`,
        data: {
          name: name,
          direction: direction,
          coordinate: coordinate,
          district: district,
          horoo: horoo,
          parameters: {
            limit_minute: limit_minute,
            limit_tag: limit_tag,
            limit_save: limit_save,
            limit_power: limit_power,
            limit_epc_array: limit_epc_array,
            limit_ant: limit_ant,
          },
        },
      });

      id && history(-1);
      swal("Мэдэгдэл", "Амжилттай бүртгэгдлээ", "success");
      setloading(false);
    } catch (e) {
      // if (e)

      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  return (
    <div className="flex flex-col p-2  ">
      <div className="flex justify-between items-center p-3 border-def-primary border-l-8 border-def-blue my-2">
        <div className="text-base font-semibold">Төхөөрөмжийн мэдээлэл</div>
      </div>
      {loading ? (
        <div className="flex justify-center h-full items-center">
          <img src="../../magnify.svg" />
        </div>
      ) : (
        <Form
          layout="vertical"
          initialValues={{
            id: data?.id,
            name: data?.name,
            direction: data?.direction,
            uuid: data?.uuid,
            coordinate: data?.coordinate,
            createdAt: data?.createdAt,
            district: data?.district,
            horoo: data?.horoo,
            limit_epc_array: data?.parameters?.limit_epc_array,
            limit_tag: data?.parameters?.limit_tag,
            limit_save: data?.parameters?.limit_save,
            limit_power: data?.parameters?.limit_power,
            limit_ant: data?.parameters?.limit_ant,
            limit_minute: data?.parameters?.limit_minute,
          }}
          onFinish={onFinish}
        >
          <div className="shadow mt-4 p-4">
            <div className=" flex flex-wrap ">
              <div className="w-1/2 p-2">
                <Typography.Title level={4} style={{ marginBottom: 5 }}>
                  Төхөөрөмжийн бүртгэл
                </Typography.Title>

                <Form.Item label="Төхөөрөмжийн нэр" name="name">
                  <Input placeholder="Бичих" required />
                </Form.Item>
                <Form.Item label="Төхөөрөмжийн чиглэл" name="direction">
                  <Input placeholder="Бичих" required />
                </Form.Item>
                <Form.Item label="Төхөөрөмжийн координат" name="coordinate">
                  <Input placeholder="Бичих" required />
                </Form.Item>

                <Form.Item label="Дүүрэг" name="district">
                  <Select
                    placeholder="Байршлаар хайх"
                    options={list_district}
                  />
                </Form.Item>
                <Form.Item label="Хороо" name="horoo">
                  <Input placeholder="Бичих" />
                </Form.Item>
              </div>
              <div className="w-1/2 p-2">
                <Typography.Title level={4} style={{ marginBottom: 5 }}>
                  Төхөөрөмжийн тохиргоо
                </Typography.Title>

                <Form.Item label="EPC" name="limit_epc_array">
                  <Input placeholder="Бичих" />
                </Form.Item>
                <Form.Item label="TAG " name="limit_tag">
                  <Input placeholder="Бичих" />
                </Form.Item>
                <Form.Item label="SAVE" name="limit_save">
                  <Input placeholder="Бичих" />
                </Form.Item>
                <Form.Item label="POWER" name="limit_power">
                  <Input placeholder="Бичих" />
                </Form.Item>
                <Form.Item label="ANT" name="limit_ant">
                  <Input placeholder="Бичих" />
                </Form.Item>
                <Form.Item label="MINUTE" name="limit_minute">
                  <Input placeholder="Бичих" />
                </Form.Item>
              </div>
            </div>
            <div className="flex  justify-between my-4 items-center">
              <Button
                danger
                icon={<AiOutlineArrowLeft className="ml-2" />}
                size="middle"
                onClick={() => history(-1)}
              />

              <Form.Item>
                <Button
                  danger
                  type="primary"
                  size="middle"
                  htmlType="submit"
                  loading={loading}
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Index;

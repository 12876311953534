import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useAuth } from "../utils/provider";
import Online from "./online";
import Offline from "./offline";
import { Spin, Modal } from "antd";
import Table from "../components/table";
import {
  AiFillTag,
  AiOutlineRadarChart,
  AiOutlineNumber,
} from "react-icons/ai";
import { CiMobile4, CiCalendarDate } from "react-icons/ci";
import { GrDirections } from "react-icons/gr";
import { BiTime } from "react-icons/bi";
import { RiRouterFill } from "react-icons/ri";
import { AiFillCar } from "react-icons/ai";
import { socket } from "../utils/socket";
import objectPath from "object-path";
import moment from "moment";
import { FORMAT_DATE } from "../utils/const";
import { mainApi } from "../utils/api";
import axios from "axios";
const columns = [
  {
    icon: <AiFillTag size={20} color="#9291A5" />,
    title: "EPC",
    field: "epc_num",
    width: 250,
    render: ({ data }) => {
      return (
        <div className="underline hover:cursor-pointer">
          <div>{`EPC : ${data?.epc_num}`}</div>
          <div>{`TID : ${data?.tid_num}`}</div>
        </div>
      );
    },
  },

  {
    icon: <AiFillCar size={20} color="#9291A5" />,
    title: "Улсын дугаар",
    field: "plate_chars",
    width: 120,
  },
  {
    icon: <CiCalendarDate size={20} color="#9291A5" />,
    title: "Огноо",
    field: "data",
    width: 100,
  },
  {
    icon: <BiTime size={20} color="#9291A5" />,
    title: "Цаг, минут",
    field: "time",
    width: 100,
  },

  {
    icon: <CiMobile4 size={20} color="#9291A5" />,
    title: "Төхөөрөмж нэр",
    field: "manager.name",
    width: 180,
  },
  {
    icon: <GrDirections size={20} color="#9291A5" />,
    title: "Төхөөрөмж чиглэл",
    field: "manager.direction",
  },
];
const Index = () => {
  const [loading, setloading] = useState(false);

  const { logout } = useAuth();
  const [open, setopen] = useState(false);
  const [device, setdevice] = useState(null);
  const [key, setkey] = useState(null);
  const [dataloading, setdataloading] = useState(false);
  const [data, setdata] = useState(null);
  const [managers, setmanagers] = useState([]);
  const [offset, setoffset] = useState(1);
  const [counts, setcounts] = useState(null);
  useEffect(() => {
    fetchKey();
    fetchSocket();
  }, []);
  const fetchKey = async () => {
    try {
      setloading(true);
      const result = await axios({
        url: "https://cloudgis.mn/map/v1/init/pc?mskey=94109fb6e6f703f7793ebded1ea74cd1",
        method: "GET",
        headers: {},
      });

      setkey(result?.data?.ssid);
      setloading(false);
    } catch (e) {
      // if (e)
      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  useEffect(() => {
    device != null && fetchData();
  }, [device, offset]);
  const fetchSocket = async () => {
    setloading(true);
    try {
      socket.on("device-status", (res) => {
        const manager = objectPath.get(res, "managers");

        const online = manager.filter((row) => row.status.toString() == "true");

        const offline = manager.filter(
          (row) => row.status.toString() != "true"
        );

        setcounts({
          online: online.length,
          offline: offline.length,
        });
        setmanagers(res.managers);
        setloading(false);
      });
    } catch (e) {
      setloading(false);
    }
  };
  const fetchData = async () => {
    setopen(true);
    setdataloading(true);
    try {
      const res = await mainApi({
        url: `/api/v1/searchrfid?page=${offset}`,
        method: "POST",
        data: {
          field: "uuid",
          value: device?.uuid,
        },
      });
      console.log("🚀 ~ fetchData ~ res:", res.data);

      setdata(res.data);
    } catch (e) {
      setdataloading(false);
    }
    setdataloading(false);
  };
  // 527d7a63-947d-4f09-aebc-36a27d2dcc2d
  const position = [47.918662, 106.917874];
  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center  items-center">
        <div className="flex justify-center items-center mx-auto mt-4">
          {key == null ? (
            <div className="flex justify-center items-center w-screen">
              <Spin />
            </div>
          ) : (
            <div id="map" className=" h-screen">
              {counts != null && (
                <div className="flex flex-row p-2">
                  <div className="flex flex-row justify-center items-center">
                    <img
                      src={require("./online.png")}
                      style={{ width: 30, height: 30 }}
                    />
                    <div className="font-semibold pl-4">{counts?.online}</div>
                  </div>
                  <div className="flex flex-row justify-center items-center ml-8">
                    <img
                      src={require("./offline.png")}
                      style={{ width: 30, height: 30 }}
                    />
                    <div className="font-semibold pl-4">{counts?.offline}</div>
                  </div>
                  <div className="flex flex-row justify-center items-center ml-8">
                    <img
                      src={require("./total.png")}
                      style={{ width: 30, height: 30 }}
                    />
                    <div className="font-semibold pl-4">
                      {counts?.offline + counts?.online}
                    </div>
                  </div>
                </div>
              )}
              <MapContainer
                zoom={14}
                center={position}
                style={{ height: "100vh", width: "100wh" }}
              >
                <TileLayer
                  url={`https://cloudgis.mn/map/v1/tilemap/std/{z}/{x}/{y}?ssid=${key}`}
                />
                {managers.length > 0 &&
                  managers?.map((row, index) => {
                    const pos = objectPath.get(row, "coordinate").split(",");
                    const status = objectPath.get(row, "status");

                    const Icon = status.toString() == "true" ? Online : Offline;
                    if (
                      /[\.]/.test(String(pos[0])) &&
                      /[\.]/.test(String([pos[1]]))
                    ) {
                      return (
                        <Marker
                          position={[pos[0], pos[1]]}
                          key={index}
                          icon={Icon}
                        >
                          <Popup>
                            {row?.name}
                            <br /> {row?.direction}
                            <br />
                            <button
                              className="text-blue-600 underline"
                              onClick={() => {
                                setdevice(row);
                              }}
                            >
                              дэлгэрэнгүй харах
                            </button>
                          </Popup>
                        </Marker>
                      );
                    }
                  })}
              </MapContainer>
            </div>
          )}
          <Modal
            title="Дэлгэрэнгүй мэдээлэл"
            open={open}
            onOk={() => setopen(false)}
            onCancel={() => setopen(false)}
            okButtonProps={{ disabled: true }}
            cancelButtonProps={{ disabled: false }}
            className=""
            width={1200}
            footer={false}
            confirmLoading={dataloading}
          >
            {dataloading ? (
              <div className="flex  justify-center items-center h-64">
                <Spin />
              </div>
            ) : (
              <div>
                <div className=" flex ">
                  <div className="w-1/3 p-4">
                    <div>Төхөөрөмжийн нэр</div>
                    <div className="text-base font-semibold">
                      {device?.name}
                    </div>
                    {/* <div>Сүүлд илгээсэн огноо</div>
                    <div className="text-base font-semibold">
                      {moment(device?.updatedAt).format(FORMAT_DATE)}
                    </div> */}
                  </div>
                  <div className="w-1/3 p-4">
                    <div>Төхөөрөмжийн нэр</div>
                    <div className="text-base font-semibold">
                      {device?.direction}
                    </div>
                  </div>
                  <div className="w-1/3 p-4">
                    <div>Төхөөрөмжийн координат</div>
                    <div className="text-base font-semibold">
                      {device?.coordinate}
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col">
                  <div className="w-flex flex-col p-4">
                    {data && (
                      <Table
                        list={data?.rows}
                        columns={columns}
                        total={data?.count}
                        width={1150}
                        page={offset}
                        setpage={setoffset}
                        showPage={true}
                        showTotal={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Index;

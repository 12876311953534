import { Spin } from "antd";
import objectPath from "object-path";

const Devices = ({ stat, device }) => {
  const freememory = objectPath.get(stat, "ram.freememory");
  const totalmemory = objectPath.get(stat, "ram.totalmemory");
  const memoryPercent = 100 - Math.floor((freememory / totalmemory) * 100);
  const cores = objectPath.get(stat, "cpu.cores");
  const use = Math.floor(objectPath.get(stat, "cpu.use"));
  const cpuPercent = Math.floor((use / cores) * 100);
  const diskSize = objectPath.get(stat, "disk.size");
  const diskUse = objectPath.get(stat, "disk.used");
  const diskPercent = Number(
    objectPath.get(stat, "disk.capacity")?.replace("%", "")
  );
  const total = objectPath.get(device, "total");
  const list = objectPath.get(device, "data");
  const online = objectPath.get(device, "online");
  const onlinePerc = Math.round((online / total) * 100);
  const offline = objectPath.get(device, "offline");
  const offlinePerc = Math.round((offline / total) * 100);
  return (
    <div className="flex flex-wrap p-2 w-full">
      <div className="flex w-1/5 p-1">
        <Comp
          text={"RAM"}
          percent={memoryPercent}
          size={`${(Number(totalmemory - freememory) / 1024 ** 3)
            .toString()
            .substring(0, 4)}/${Math.ceil(Number(totalmemory) / 1024 ** 3)}GB`}
        />
      </div>
      <div className="flex w-1/5 p-1">
        <Comp text={"CPU"} percent={cpuPercent} size={`${use}/${cores} CORE`} />
      </div>
      <div className="flex w-1/5 p-1">
        <Comp
          text={"DISK"}
          percent={diskPercent}
          size={`${diskUse}/${diskSize}`}
        />
      </div>
      <div className="flex w-1/5 p-1">
        <Comp
          text={"Online"}
          percent={onlinePerc}
          color="bg-green-400"
          size={`${online}/${total}`}
        />
      </div>
      <div className="flex w-1/5 p-1">
        <Comp
          text={"Offline"}
          percent={offlinePerc}
          color="bg-red-400"
          size={`${offline}/${total}`}
        />
      </div>
    </div>
  );
};
export default Devices;

const Comp = ({ text, percent, color, size }) => {
  return (
    <div className="flex w-full items-center justify-center align-middle bg-white rounded p-2">
      <div className="font-bold text-sm">{text}</div>
      {percent ? (
        <div className="flex w-full mx-2">
          <div className="flex h-4 bg-gray-300 w-28 rounded-lg" />
          <div
            style={{ width: `${1.3 * percent}px` }}
            className={`flex h-4 rounded-lg absolute ${
              color ? color : percent < 50 ? `bg-green-400` : `bg-red-400`
            } justify-center align-middle`}
          >
            <div className="text-xs font-bold">{`${percent}%`}</div>
          </div>
        </div>
      ) : (
        <div className="flex w-full justify-center items-center">
          <Spin />
        </div>
      )}
      {percent ? <div className="font-bold text-sm">{size}</div> : <div />}
    </div>
  );
};

import React from "react";
import Table from "../../components/table";
const list = ({ list, columns, total, page, setpage, showPage, showTotal }) => {
  return (
    <Table
      page={page}
      setpage={setpage}
      total={total}
      list={list}
      columns={columns}
      isDrag={true}
      showTotal={showTotal}
      showPage={showPage}
    />
  );
};

export default list;

import React from "react";
import { socketStat } from "../utils/socketStat";
import { useState, useEffect } from "react";
import objectPath from "object-path";
import { MdOutlineSecurity, MdOutlineFactCheck } from "react-icons/md";
import { TbReceiptTax } from "react-icons/tb";
import { BiSolidDrink } from "react-icons/bi";
import { RiPhoneFindLine } from "react-icons/ri";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { DatePicker, Spin } from "antd";
import moment from "moment";
import { FORMAT_DATE } from "../utils/const";
import { FaCar } from "react-icons/fa6";
import { BsBrowserChrome } from "react-icons/bs";
import { BsChatLeftTextFill } from "react-icons/bs";
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import axios from "axios";
import { LuRefreshCcw } from "react-icons/lu";
const translate = [
  {
    key: "insurance",
    value: "Даатгал",
    icon: <MdOutlineSecurity size={24} />,
    sm: <MdOutlineSecurity size={16} />,
  },
  {
    key: "tax",
    value: "Татвар",
    icon: <TbReceiptTax size={24} />,
    sm: <TbReceiptTax size={16} />,
  },
  {
    key: "inspection",
    value: "Үзлэг, оншилгоо",
    icon: <MdOutlineFactCheck size={24} />,
    sm: <MdOutlineFactCheck size={16} />,
  },
  {
    key: "drunk",
    value: "Согтуу жолооч",
    icon: <BiSolidDrink size={24} />,
    sm: <BiSolidDrink size={16} />,
  },
  {
    key: "penalty",
    value: "Торгууль",
    icon: <LiaMoneyCheckAltSolid size={24} />,
    sm: <LiaMoneyCheckAltSolid size={16} />,
  },
  {
    key: "asap",
    value: "Эрэн сурвалжлалт",
    icon: <RiPhoneFindLine size={24} />,
    sm: <RiPhoneFindLine size={16} />,
  },
];

const Servicelog = () => {
  const [data, setdata] = useState(null);
  const [date, setdate] = useState(new Date());
  const [list, setlist] = useState(null);
  const [loading, setloading] = useState(true);
  const [loadingList, setloadingList] = useState(true);

  useEffect(() => {
    fetch();
  }, [date]);

  const fetch = async () => {
    try {
      setloading(true);
      const result = await axios({
        url: `https://vil.odt.mn/service/logStat`,
        auth: {
          username: "odt",
          password: "odt123456",
        },
        method: "POST",
        data: {
          date: moment(date).format("YYYY-MM-DD"),
        },
      });

      setdata(objectPath.get(result, "data.result"));
      setloading(false);
    } catch (err) {
      setloading(false);
      console.log("ServiceLog", err);
    }
  };

  const fetchList = async (type, success) => {
    try {
      setloadingList(true);
      const result = await axios({
        url: `https://vil.odt.mn/service/logList`,
        auth: {
          username: "odt",
          password: "odt123456",
        },
        method: "POST",
        data: {
          date: moment(date).format("YYYY-MM-DD"),
          type: type,
          success: success,
        },
      });
      console.log("🚀 ~ fetch ~ result:", type, success, result.data.result);
      setlist(objectPath.get(result, "data.result"));
      setloadingList(false);
    } catch (err) {
      setloadingList(false);
      console.log("ServiceLog", err);
    }
  };
  return (
    <div className="flex flex-wrap w-full">
      <div className="flex p-2 w-1/2">
        {loading ? (
          <div className="flex justify-center items-center w-full">
            <Spin />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center">
              <div className="font-semibold text-base">{`Серверийн жагсаалт`}</div>
              <div className="flex justify-center items-center">
                <DatePicker
                  className="border-none mr-2"
                  placeholder={moment(date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setdate(e.$d);
                  }}
                />
                <LuRefreshCcw
                  onClick={() => setdate(new Date())}
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
            {data?.map((row, i) => {
              const name = objectPath.get(row, "_id");
              const total = objectPath.get(row, "totalCount");
              const success = objectPath.get(row, "success");
              const nosuccess = objectPath.get(row, "nosuccess");
              const obj = translate.find((row) => row.key === name);
              const text = objectPath.get(obj, "value");
              const icon = objectPath.get(obj, "icon");
              return (
                <div className="flex w-full p-4 m-4 bg-white shadow rounded items-center ">
                  {icon}
                  <div className="w-48 text-left pl-2 text-base font-semibold">
                    {text}
                  </div>
                  <div className="flex align-bottom justify-end mx-2">
                    <div
                      className="text-base font-bold  hover:cursor-pointer"
                      onClick={() => {
                        fetchList(name, null);
                      }}
                    >
                      {total}
                    </div>
                    <div className="text-xs">Нийт</div>
                  </div>
                  <div className="flex align-bottom justify-end mx-2">
                    <div
                      className="text-green-500 text-base font-bold hover:cursor-pointer"
                      onClick={() => {
                        fetchList(name, true);
                      }}
                    >
                      {success}
                    </div>
                    <div className="text-xs">Амжилттай</div>
                  </div>
                  <div className="flex align-bottom justify-end mx-2">
                    <div
                      className="text-red-500 text-base font-bold  hover:cursor-pointer"
                      onClick={() => {
                        fetchList(name, false);
                      }}
                    >
                      {nosuccess}
                    </div>
                    <div className="text-xs">Амжилтгүй</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex p-2 w-1/2">
        <div className="flex flex-col w-full">
          {loadingList ? (
            <div className="flex justify-center items-center h-screen w-full">
              <Spin />
            </div>
          ) : (
            <div className="p-2">
              <div className="font-semibold text-base">{`Сүүлийн 5н хүсэлт`}</div>
              <div className="flex flex-col">
                {list?.map((row, index) => {
                  const type = objectPath.get(row, "type");
                  const obj = translate.find((row) => row.key === type);
                  const plate = objectPath.get(row, "plate");
                  const message = objectPath.get(row, "message");
                  const created = objectPath.get(row, "created");
                  const success = objectPath.get(row, "success");
                  const url = objectPath.get(row, "url");
                  const text = objectPath.get(obj, "value");
                  const icon = objectPath.get(obj, "sm");
                  const request = objectPath.get(row, "body");
                  const response = objectPath.get(row, "response");
                  return (
                    <div className="flex flex-col bg-white my-2">
                      <div
                        className={`flex items-center w-full p-1 justify-between ${
                          success == true ? "bg-green-500" : "bg-red-500"
                        }`}
                      >
                        <div className="flex items-center">
                          <div>{icon}</div>
                          <div className="text-white pl-1">{text}</div>
                        </div>
                        <div className="text-white text-base">
                          {moment(created).format(FORMAT_DATE)}
                        </div>
                      </div>
                      <div className="flex flex-col p-4">
                        <div className="flex justify-between">
                          <div className="flex items-center">
                            <FaCar />
                            <div className="pl-2 font-semibold">{plate}</div>
                          </div>
                          <div className="flex items-center">
                            <BsBrowserChrome />
                            <div className="pl-2 font-semibold">{url}</div>
                          </div>
                        </div>
                        <div className="flex items-start mt-2">
                          <BsChatLeftTextFill className="mt-2" />
                          <div className="pl-2">{message}</div>
                        </div>
                      </div>
                      <div className="flex p-2">
                        <div className="w-1/2">
                          <div className="text-sm font-bold">Хүсэлт : </div>
                          <JsonView
                            data={request}
                            shouldExpandNode={allExpanded}
                            style={defaultStyles}
                          />
                        </div>
                        <div className="w-1/2">
                          <div className="text-sm font-bold">Хариу : </div>
                          <JsonView
                            data={response}
                            shouldExpandNode={allExpanded}
                            style={defaultStyles}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Servicelog;

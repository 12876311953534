import objectPath from "object-path";
import Line from "./components/lineCount";
import { Spin } from "antd";
import { LuRefreshCcw } from "react-icons/lu";
import { DatePicker } from "antd";
import moment from "moment";
const violation = ({ data, fetch, loading, setdate, date }) => {
  console.log("🚀 ~ violation ~ data:", data);
  const carTotal = objectPath.get(data, "total");

  const carUB = objectPath.get(data, "ub");
  const carCountry = objectPath.get(data, "country");

  const taxPaid = objectPath.get(data, "tax");
  const taxNotPaid = objectPath.get(data, "notax");

  const inspectionPassed = objectPath.get(data, "inspection");
  const inspectionNotPassed = objectPath.get(data, "noinspection");

  const penalty = objectPath.get(data, "penalty");
  const nopenalty = objectPath.get(data, "nopenalty");

  const insurance = objectPath.get(data, "insurance");
  const noInsurance = objectPath.get(data, "noinsurance");

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center pr-4">
          <div className="font-bold text-lg">Зөрчлийн мэдээ /Өнөөдөр/</div>
          <div className="flex justify-center items-center">
            <DatePicker
              className="border-none mr-2"
              placeholder={moment(date).format("YYYY-MM-DD")}
              onChange={(e) => {
                setdate(e.$d);
              }}
            />
            <LuRefreshCcw
              onClick={() => setdate(new Date())}
              className="hover:cursor-pointer"
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <Line
            text={
              loading == false ? (
                ` ${carTotal
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Нийт машин /давхардсан/`}
          />

          <Line
            text={
              loading == false ? (
                ` ${carUB
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Нийт машин /Нийслэл/`}
          />
          <Line
            text={
              loading == false ? (
                ` ${carCountry
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Нийт машин /Орон нутаг/`}
          />
          <Line
            text={
              loading == false ? (
                ` ${taxPaid
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Татвар төлсөн`}
          />
          <Line
            text={
              loading == false ? (
                ` ${taxNotPaid
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Татвар төлөөгүй`}
          />
          <Line
            text={
              loading == false ? (
                ` ${inspectionPassed
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Үзлэгт тэнцсэн`}
          />
          <Line
            text={
              loading == false ? (
                ` ${inspectionNotPassed
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Үзлэгт тэнцээгүй`}
          />
          <Line
            text={
              loading == false ? (
                ` ${penalty
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Торгуультай`}
          />
          <Line
            text={
              loading == false ? (
                ` ${nopenalty
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Торгуульгүй`}
          />
          <Line
            text={
              loading == false ? (
                ` ${insurance
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Даатгалтай`}
          />
          <Line
            text={
              loading == false ? (
                ` ${noInsurance
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Даатгалгүй`}
          />
        </div>
      </div>
    </div>
  );
};
export default violation;

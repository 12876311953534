import React from "react";
import { socketStat } from "../utils/socketStat";
import { useState, useEffect } from "react";
import objectPath from "object-path";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { mainApi } from "../utils/api";
import { Spin, Tabs, Statistic as Stat, DatePicker } from "antd";
import StatTable from "../components/StatTable";
import moment from "moment";
import axios from "axios";
import { LuRefreshCcw } from "react-icons/lu";
const FROMS = [
  {
    key: "total",
    title: "Нийт",
  },
  {
    key: "rfid",
    title: "RFID",
  },
  {
    key: "citylpr",
    title: "CityLPR",
  },
  {
    key: "police",
    title: "ТЦА",
  },
  {
    key: "tollgate",
    title: "ТАЦ",
  },
];
const Item = ({ ok, okText, wrong, wrongText, text }) => {
  const total = ok + wrong;
  const okPercent = parseFloat((ok / total) * 100).toFixed(1);
  const wrongPercent = parseFloat((wrong / total) * 100).toFixed(1);

  return (
    <div className="flex m-1 flex-col bg-white shadow rounded p-2">
      <div className="decoration-solid text-center font-bold">{text}</div>
      <div className="flex flex-col w-full border-t mt-2">
        <div className="text-sm font-semibold">{`${okText} - ${ok}`}</div>
        <div
          style={{ width: `${okPercent}%`, minWidth: 50 }}
          className="bg-green-500 justify-center items-center flex text-sm p-1 rounded-lg h-4 min-w-16"
        >
          <div className="text-white font-normal">{`${okPercent}%`}</div>
        </div>
      </div>
      <div className="flex flex-col w-full mt-2">
        <div className="text-sm font-semibold">{`${wrongText} - ${wrong}`}</div>
        <div
          style={{ width: `${wrongPercent}%`, minWidth: 50 }}
          className="bg-red-500 justify-center items-center flex text-sm p-1 rounded-lg h-4 min-w-16"
        >
          <div className="text-white font-normal">{`${wrongPercent}%`}</div>
        </div>
      </div>
    </div>
  );
};
const Statistic = () => {
  const [rfid, setrfid] = useState(null);
  const [citylrp, setcitylpr] = useState(null);
  const [tats, settats] = useState(null);
  const [ttsa, setttsa] = useState(null);
  const [date, setdate] = useState(new Date());
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetch();
  }, [date]);

  const fetch = async () => {
    try {
      setloading(true);
      const result = await axios({
        url: `https://vil.odt.mn/service/system`,
        auth: {
          username: "odt",
          password: "odt123456",
        },
        method: "POST",
        data: {
          date: moment(date).format("YYYY-MM-DD"),
        },
      });

      setrfid(dataFixer(objectPath.get(result, "data.rfid")));
      setttsa(dataFixer(objectPath.get(result, "data.police")));
      setcitylpr(dataFixer(objectPath.get(result, "data.citylpr")));
      settats(dataFixer(objectPath.get(result, "data.tollgate")));
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("��� ~ fetch ~ error:", error);
    }
  };

  return (
    <div className="flex flex-wrap w-full">
      <div className="flex w-full p-2 flex-row justify-between items-center">
        <div>Жагсаалт</div>
        <div className="flex justify-center items-center">
          <DatePicker
            className="border-none mr-2"
            placeholder={moment(date).format("YYYY-MM-DD")}
            onChange={(e) => {
              setdate(e.$d);
            }}
          />
          <LuRefreshCcw
            onClick={() => setdate(new Date())}
            className="hover:cursor-pointer"
          />
        </div>
      </div>
      <StatTable
        loading={loading}
        from={"RFID-н илрүүлсэн зөрчил"}
        data={rfid}
      />
      <StatTable
        loading={loading}
        from={"ТЦА-н илрүүлсэн зөрчил"}
        data={ttsa}
      />
      <StatTable
        loading={loading}
        from={"CityLRP-н илрүүлсэн зөрчил"}
        data={citylrp}
      />
      <StatTable
        loading={loading}
        from={"ТАЦ-н илрүүлсэн зөрчил"}
        data={tats}
      />
    </div>
  );
};
const dataFixer = (data, from) => {
  const taxObj = {};
  const penaltyObj = {};
  const insuranceObj = {};
  const inspectionObj = {};
  const drunkObj = {};
  const asapObj = {};

  let tempTotal = 0;
  data?.map((row) => {
    const total = objectPath.get(row, "total");
    const location = objectPath.get(row, "location");
    const tax = objectPath.get(row, "tax");
    const notax = objectPath.get(row, "notax");
    const penalty = objectPath.get(row, "penalty");
    const nopenalty = objectPath.get(row, "nopenalty");
    const drunk = objectPath.get(row, "drunk");
    const nodrunk = objectPath.get(row, "nodrunk");
    const asap = objectPath.get(row, "asap");
    const noasap = objectPath.get(row, "noasap");
    const inspection = objectPath.get(row, "inspection");
    const noinspection = objectPath.get(row, "noinspection");
    const insurance = objectPath.get(row, "insurance");
    const noinsurance = objectPath.get(row, "noinsurance");
    pusher(taxObj, location, tax, notax);
    pusher(penaltyObj, location, penalty, nopenalty);
    pusher(insuranceObj, location, insurance, noinsurance);
    pusher(inspectionObj, location, inspection, noinspection);
    pusher(drunkObj, location, drunk, nodrunk);
    pusher(asapObj, location, asap, noasap);
    tempTotal += total;
  });
  return {
    from: "from",
    total: tempTotal,
    data: [
      {
        name: "Татвар",
        ...taxObj,
      },
      {
        name: "Даатгал",
        ...insuranceObj,
      },
      {
        name: "Оншилгоо",
        ...inspectionObj,
      },
      {
        name: "Торгууль",
        ...penaltyObj,
      },
      {
        name: "Согтуу жолооч",
        ...drunkObj,
      },
      {
        name: "Эрэн сурвалжлалт",
        ...asapObj,
      },
    ],
  };
};
const pusher = (Obj, location, val, noval) => {
  if (location == "UB") {
    Obj.ub_val = val;
    Obj.ub_no_val = noval;
  } else {
    Obj.c_val = val;
    Obj.c_no_val = noval;
  }
};
export default Statistic;

const list_district = [
  {
    label: "Баянзүрх",
    value: "Баянзүрх",
  },
  {
    label: "Хануул",
    value: "Хануул",
  },
  {
    label: "Баянгол",
    value: "Баянгол",
  },
  {
    label: "Чингэлтэй",
    value: "Чингэлтэй",
  },
  {
    label: "Сүхбаатар",
    value: "Сүхбаатар",
  },
  {
    label: "Сонгинохайрхан",
    value: "Сонгинохайрхан",
  },
];
const check_list = [
  {
    label: "Бүгд",
    value: 0,
  },
  {
    label: "Улсын дугаартай",
    value: 1,
  },
  {
    label: "Улсын дугааргүй",
    value: 2,
  },
];
const distinct_list = [
  {
    label: "Давхцалтай",
    value: 0,
  },
  {
    label: "Давхцалгүй",
    value: 1,
  },
];
const FORMAT_DATE = "YYYY-MM-DD HH:mm";
const BASE_URL = "https://etc.ulaanbaatar.mn";
const SOCKET_ID = "559a0a0c-9a18-438a-b5be-8441c15c5b6e";
const REMEMBER_KEY = "RFID";
export {
  list_district,
  BASE_URL,
  FORMAT_DATE,
  SOCKET_ID,
  REMEMBER_KEY,
  check_list,
  distinct_list,
};

import React, { useState } from "react";
import { Form, Input, Spin, Checkbox } from "antd";
import { useAuth } from "../utils/provider";
import swal from "sweetalert";
import {
  GetFromStorage,
  RemoveFromStorage,
  SaveToStorage,
} from "../utils/storage";
import { REMEMBER_KEY } from "../utils/const";
import { mainApi } from "../utils/api";

const Login = () => {
  const { login } = useAuth();
  const [loading, setloading] = useState(false);
  const [remembered] = useState(GetFromStorage(REMEMBER_KEY));
  const [remember, setremember] = useState(
    remembered == undefined ? false : true
  );

  const onFinish = async ({ username, password }) => {
    setloading(true);
    try {
      const res = await mainApi({
        url: "/auth/login",
        method: "POST",
        data: {
          email: username,
          password: password,
        },
      });

      if (remember) {
        SaveToStorage(REMEMBER_KEY, username);
      } else {
        RemoveFromStorage(REMEMBER_KEY);
      }

      login(res.data);
      setloading(false);
    } catch (err) {
      swal("Мэдэгдэл!", err?.response?.data, "error");

      setloading(false);
    }
  };
  const onFinishFailed = () => {};

  return (
    <div className="w-full h-screen flex justify-center items-center content-center">
      <div className="flex">
        <Form
          name="basic"
          initialValues={{
            username: remembered ? remembered : "",
            password: "",
          }}
          onFinish={onFinish}
          className="text-base font-semibold border-def-primary"
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <div className="flex justify-center items-center mb-8">
            <img src="../logo.webp" className="w-36" alt="logo" />
          </div>
          <Form.Item
            label="Нэвтрэх нэр"
            name="username"
            className="text-base font-semibold border-def-primary w-96"
            rules={[{ required: true, message: "Нэвтрэх нэрээ оруулна уу!" }]}
          >
            <Input className="mt-2 p-3 text-base font-semibold " />
          </Form.Item>

          <Form.Item
            label="Нууц үг"
            name="password"
            className="text-base font-semibold border-def-primary"
            rules={[{ required: true, message: "Нууц үгээ оруулна уу!" }]}
          >
            <Input.Password className="mt-2 p-3 text-base font-semibold text-def-primary" />
          </Form.Item>
          <Checkbox
            value={remember}
            onChange={(e) => setremember(e.target.checked)}
          >
            Сануулах
          </Checkbox>
          <Form.Item className="mt-8">
            {loading ? (
              <div className="flex justify-center items-center">
                <Spin />
              </div>
            ) : (
              <button
                type="primary"
                htmlType="submit"
                className="bg-def-primary p-3.5 rounded-lg text-white font-semibold text-base w-96 text-center"
              >
                Нэвтрэх
              </button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;

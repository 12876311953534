import { Spin, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useAuth } from "../../utils/provider";
import { MdWifi, MdWifiOff } from "react-icons/md";
import moment from "moment";
import { FORMAT_DATE } from "../../utils/const";
import Item from "../../tag/components/item";
import Table from "../../components/table";
import { RiRouterFill } from "react-icons/ri";
import Icon from "./Icon";
import { FaTemperatureLow } from "react-icons/fa";
import { AiOutlineUnorderedList, AiFillCar } from "react-icons/ai";
import {
  AiFillTag,
  AiOutlineRadarChart,
  AiOutlineNumber,
} from "react-icons/ai";
import { CiMobile4, CiCalendarDate } from "react-icons/ci";
import { GrDirections } from "react-icons/gr";
import { BiTime } from "react-icons/bi";
import { mainApi } from "../../utils/api";
const columns = [
  {
    icon: <AiFillTag size={20} color="#9291A5" />,
    title: "EPC",
    field: "epc_num",
    width: 250,
    render: ({ data }) => {
      return (
        <div className="underline hover:cursor-pointer">
          <div>{`EPC : ${data?.epc_num}`}</div>
          <div>{`TID : ${data?.tid_num}`}</div>
        </div>
      );
    },
  },

  {
    icon: <AiFillCar size={20} color="#9291A5" />,
    title: "Улсын дугаар",
    field: "car_no",
    width: 120,
  },

  {
    icon: <RiRouterFill size={20} color="#9291A5" />,
    title: "Антине",
    field: "antenna_num",
    width: 70,
  },
  {
    icon: <AiOutlineNumber size={20} color="#9291A5" />,
    title: "Уншигдсан тоо",
    field: "count_num",
    width: 120,
  },
  {
    icon: <AiOutlineRadarChart size={20} color="#9291A5" />,
    title: "RSSI түвшин",
    field: "rssi_level",
    width: 100,
  },

  {
    icon: <CiMobile4 size={20} color="#9291A5" />,
    title: "Төхөөрөмж нэр",
    field: "manager.name",
    width: 180,
  },
  {
    icon: <GrDirections size={20} color="#9291A5" />,
    title: "Төхөөрөмж чиглэл",
    field: "manager.direction",
    width: 250,
  },
  {
    icon: <CiCalendarDate size={20} color="#9291A5" />,
    title: "Огноо",
    field: "data",
  },
  {
    icon: <BiTime size={20} color="#9291A5" />,
    title: "Цаг, минут",
    field: "time",
  },
];
const Map = () => {
  const [loading, setloading] = useState(false);
  const [key, setkey] = useState(null);
  const { logout } = useAuth();
  const [open, setopen] = useState(null);
  const [device, setdevice] = useState(null);
  const [dataloading, setdataloading] = useState(false);
  const [data, setdata] = useState(null);
  const [managers, setmanagers] = useState([]);
  const [offset, setoffset] = useState(1);
  useEffect(() => {
    fetchManagers();
  }, []);
  useEffect(() => {
    device != null && fetchData();
  }, [device, offset]);

  const fetchManagers = async () => {
    const result = await mainApi({
      url: "/api/v1/manager?limit=500",
      method: "GET",
    });

    setmanagers(result?.data?.rows);
  };

  const fetchData = async () => {
    setopen(true);
    setdataloading(true);
    try {
      const res = await mainApi({
        url: `/api/v1/searchrfid?page=${offset}`,
        method: "POST",
        data: {
          field: "uuid",
          value: device?.uuid,
        },
      });

      setdata(res.data);
    } catch (e) {
      setdataloading(false);
    }
    setdataloading(false);
  };

  // 527d7a63-947d-4f09-aebc-36a27d2dcc2d
  const position = [47.918662, 106.917874];

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center  items-center">
        <div className="mt-4">
          {loading && key != null ? (
            <Spin />
          ) : (
            <div id="map" className=" h-screen">
              <MapContainer
                zoom={14}
                center={position}
                style={{ height: "100vh", width: "100wh" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {managers.length > 0 &&
                  managers?.map((row, index) => {
                    const pos = row?.coordinate.split(",");

                    if (
                      /[\.]/.test(String(pos[0])) &&
                      /[\.]/.test(String([pos[1]]))
                    ) {
                      return (
                        <Marker
                          position={[pos[0], pos[1]]}
                          key={index}
                          icon={Icon}
                        >
                          <Popup>
                            {row?.name}
                            <br /> {row?.direction}
                            <br />
                            <button
                              className="text-blue-600 underline"
                              onClick={() => {
                                setdevice(row);
                              }}
                            >
                              дэлгэрэнгүй харах
                            </button>
                          </Popup>
                        </Marker>
                      );
                    }
                  })}
              </MapContainer>
            </div>
          )}
          <Modal
            title="Дэлгэрэнгүй мэдээлэл"
            open={open}
            onOk={() => setopen(false)}
            onCancel={() => setopen(false)}
            okButtonProps={{ disabled: true }}
            cancelButtonProps={{ disabled: false }}
            className=""
            width={1200}
            footer={false}
            confirmLoading={dataloading}
          >
            {dataloading ? (
              <div className="flex  justify-center items-center h-64">
                <Spin />
              </div>
            ) : (
              <div>
                <div className=" flex ">
                  <div className="w-1/3 p-4">
                    <div>Төхөөрөмжийн нэр</div>
                    <div className="text-base font-semibold">
                      {device?.name}
                    </div>
                  </div>
                  <div className="w-1/3 p-4">
                    <div>Төхөөрөмжийн нэр</div>
                    <div className="text-base font-semibold">
                      {device?.direction}
                    </div>
                  </div>
                  <div className="w-1/3 p-4">
                    <div>Төхөөрөмжийн координат</div>
                    <div className="text-base font-semibold">
                      {device?.coordinate}
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col">
                  <div className="w-flex flex-col p-4">
                    {data && (
                      <Table
                        list={data?.rows}
                        columns={columns}
                        total={data?.count}
                        width={1150}
                        page={offset}
                        setpage={setoffset}
                        showPage={true}
                        showTotal={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Map;

import axios from "axios";
import React, { useState, useEffect } from "react";
import { Input, Pagination, Table } from "antd";
import objectPath from "object-path";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
const check = (val) => {
  return val ? "Зөрчилтэй" : "Зөрчилгүй";
};
const checkTax = (val) => {
  const current = val?.filter((row) => row.year == moment().year().toString());
  console.log("🚀 ~ checkTax ~ current:", current);
  const air = objectPath.get(current, "0.airPollAmount");
  const tax = objectPath.get(current, "0.taxAmount");
  const trafic = objectPath.get(current, "0.trafficAmount");
  const year = objectPath.get(current, "0.year");
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex">
        {year && <div className="font-bold">{`${year}`}</div>}
        <div
          className={`font-bold pl-2 ${
            year ? "text-green-500" : "text-red-500"
          }`}
        >{`${year ? "Төлсөн" : "Төлөөгүй"}`}</div>
      </div>
      {year && (
        <div className="flex justify-center items-center flex-col">
          <div className="flex">
            <div>АЗАТөлбөр :</div>
            <div className="flex pl-1">
              <div className="font-bold">{`${trafic}₮`}</div>
            </div>
          </div>
          <div className="flex">
            <div>АБТөлбөр :</div>
            <div className="flex pl-1">
              <div className="font-bold">{`${air}₮`}</div>
            </div>
          </div>
          <div className="flex">
            <div>АТБӨЯХТатвар :</div>
            <div className="flex pl-1">
              <div className="font-bold">{`${tax}₮`}</div>
            </div>
          </div>
          <div className="flex">
            <div>Нийт :</div>
            <div className="flex pl-1">
              <div className="font-bold">{`${
                parseInt(tax) + parseInt(trafic) + parseInt(air)
              }₮`}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const checkPenalty = (val) => {
  let notPaidAmount = 0;
  let PaidAmount = 0;
  let total = 0;
  let paid = 0;
  let notPaid = 0;
  val?.map((row) => {
    total = total + 1;
    if (row.paid == true) {
      PaidAmount = PaidAmount + row.amount;
      paid++;
    }
    if (row.paid == false) {
      notPaid++;
      notPaidAmount = notPaidAmount + row.amount;
    }
  });
  return (
    <div className="flex justify-center items-center flex-col">
      <div className="flex">
        <div>Нийт : </div>
        <div className="pl-1 font-bold">{total}</div>
      </div>
      <div className="flex">
        <div>Төлсөн :</div>
        <div className="flex pl-1">
          <div className="font-bold">{paid}</div>
          <div className="pl-2 text-green-500 font-semibold">{`/ ${PaidAmount}₮ /`}</div>
        </div>
      </div>
      <div className="flex">
        <div>Төлөөгүй :</div>
        <div className="flex pl-1">
          <div className="font-bold">{notPaid}</div>
          <div className="pl-2 text-red-500 font-semibold">{`/ ${notPaidAmount} ₮/`}</div>
        </div>
      </div>
    </div>
  );
};
const Car = () => {
  const [list, setlist] = useState();
  const [loading, setloading] = useState();
  const [page, setpage] = useState(0);
  const [total, settotal] = useState(0);
  const [plate, setplate] = useState(null);
  const fetch = async () => {
    try {
      setloading(true);
      let find = {};
      if (plate) {
        find = {
          plate: { $regex: plate, $options: "i" },
        };
      }
      const result = await axios({
        url: `https://vil.odt.mn/car/table`,
        auth: {
          username: "odt",
          password: "odt123456",
        },
        method: "POST",
        data: {
          offset: page,
          find: find,
        },
      });

      settotal(result.data.total);
      setlist(result.data.items);
      setloading(false);
    } catch (err) {
      console.log("Error fetching", err);
      setloading(false);
    }
  };
  useEffect(() => {
    fetch();
  }, [page, plate]);
  return (
    <div>
      <Table
        loading={loading}
        title={() => (
          <div className="flex justify-between align-middle items-center">
            <div className="font-bold text-base">
              Нийт бүртгэгдсэн машины жагсаалт
            </div>
            <Input
              className="w-48"
              onChange={(e) => {
                console.log("e", e.target.value);
                setplate(e.target.value);
              }}
              placeholder="Улсын дугаар"
              prefix={<SearchOutlined />}
            />
          </div>
        )}
        footer={() => (
          <div className="flex justify-between align-middle items-center">
            <div className="font-bold">{`Нийт : ${total}`}</div>
            <Pagination
              defaultCurrent={1}
              total={total}
              pageSize={20}
              showSizeChanger={false}
              showQuickJumper
              onChange={(e) => {
                setpage(e - 1);
              }}
            />
          </div>
        )}
        className="w-full min-w-full"
        style={{ width: "100%", minWidth: "100%" }}
        columns={[
          { title: "Улсын №", dataIndex: "plate", key: "plate", width: 120 },
          { title: "Марк", dataIndex: "mark", key: "mark" },
          { title: "Модел", dataIndex: "model", keys: "model" },
          {
            title: "Татвар",
            dataIndex: "",
            render: (row) => {
              const tax = objectPath.get(row, "tax");
              const taxTime = objectPath.get(row, "taxDate");
              const taxResponse = objectPath.get(
                row,
                "taxResponse.return.response.listData"
              );

              return (
                <div className="flex flex-col justify-center items-center">
                  <div
                    className={`font-semibold  ${
                      !tax ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {check(taxResponse)}
                  </div>
                  <div className="text-xs font-semibold text-center">
                    {moment(taxTime).format("yyyy-MM-DD HH:mm")}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Даатгал",
            dataIndex: "",
            render: (row) => {
              const insurance = objectPath.get(row, "insurance");
              const insuranceTime = objectPath.get(row, "insuranceDate");

              return (
                <div className="flex flex-col justify-center items-center">
                  <div
                    className={`font-semibold  ${
                      insurance ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {check(insurance)}
                  </div>
                  <div className="text-xs font-semibold text-center">
                    {moment(insuranceTime).format("yyyy-MM-DD HH:mm")}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Оншилгоо",
            dataIndex: "",
            render: (row) => {
              const inspection = objectPath.get(row, "inspection");
              const inspectionTime = objectPath.get(row, "inspectionDate");

              return (
                <div className="flex flex-col justify-center items-center">
                  <div
                    className={`font-semibold  ${
                      inspection ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {check(inspection)}
                  </div>
                  <div className="text-xs font-semibold text-center">
                    {moment(inspectionTime).format("yyyy-MM-DD HH:mm")}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Торгууль",
            dataIndex: "",
            render: (row) => {
              const penalty = objectPath.get(row, "penalty");

              const penaltyTime = objectPath.get(row, "penaltyDate");

              return (
                <div className="flex flex-col justify-center items-center">
                  <div
                    className={`font-semibold  ${
                      penalty ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {check(penalty)}
                  </div>
                  <div className="text-xs font-semibold text-center">
                    {moment(penaltyTime).format("yyyy-MM-DD HH:mm")}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Эрэн сурвалжлалт",
            dataIndex: "",
            render: (row) => {
              const asap = objectPath.get(row, "asap");
              const asapTime = objectPath.get(row, "asapDate");

              return (
                <div className="flex flex-col justify-center items-center">
                  <div
                    className={`font-semibold  ${
                      asap ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {check(asap)}
                  </div>
                  <div className="text-xs font-semibold text-center">
                    {moment(asapTime).format("yyyy-MM-DD HH:mm")}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Согтуу жолооч",
            dataIndex: "",
            render: (row) => {
              const drunk = objectPath.get(row, "drunk");
              const drunkTime = objectPath.get(row, "drunkDate");
              return (
                <div className="flex flex-col justify-center items-center">
                  <div
                    className={`font-semibold  ${
                      drunk ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {check(drunk)}
                  </div>
                  <div className="text-xs font-semibold text-center">
                    {moment(drunkTime).format("yyyy-MM-DD HH:mm")}
                  </div>
                </div>
              );
            },
          },
        ]}
        expandable={{
          expandedRowRender: (record) => {
            const insurer_state = objectPath.get(
              record,
              "insuranceResponse.Contracts.0.insurer_state"
            );
            const insurance_company = objectPath.get(
              record,
              "insuranceResponse.Contracts.0.insurance_company"
            );
            const passed =
              moment(new Date()) <
              moment(
                objectPath.get(record, "inspectionResponse.response.expireDate")
              );

            const checkDate = objectPath.get(
              record,
              "inspectionResponse.response.checkDate"
            );
            const expireDate = objectPath.get(
              record,
              "inspectionResponse.response.expireDate"
            );
            const listData = objectPath.get(
              record,
              "penaltyResponse.return.response.listData"
            );

            const penalty = objectPath.get(record, "penalty");

            const asap = objectPath.get(record, "asap");
            const asapResponse = objectPath.get(record, "asapResponse");

            const drunk = objectPath.get(record, "drunk");
            const drunkResponse = objectPath.get(record, "drunkResponse");

            const taxResponse = objectPath.get(
              record,
              "taxResponse.return.response.listData"
            );

            return (
              <div className="flex">
                <div className="flex flex-col p-2 border-r w-1/6">
                  <div className="text-center font-bold">Татвар</div>
                  <div className="flex flex-col">{checkTax(taxResponse)}</div>
                </div>
                <div className="flex flex-col p-2 border-r w-1/6">
                  <div className="text-center font-bold">Даатгал</div>
                  <div className="flex flex-col">
                    <div className="flex flex-col justify-center items-center">
                      <div
                        className={`font-bold text-xs ${
                          insurer_state == "Active"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {insurer_state == "Active" ? "Идэвхтэй" : "Идэвхгүй"}
                      </div>
                      <div className="text-base text-center">
                        {insurance_company}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-2 border-r w-1/6">
                  <div className="text-center font-bold">Оншилгоо</div>
                  <div className="flex flex-col">
                    <div className="flex flex-col justify-center items-center">
                      <div
                        className={`font-bold text-xs ${
                          passed == true ? "text-green-500" : "text-red-500"
                        }`}
                      >
                        {passed == true ? "Тэнцсэн" : "Тэнцээгүй"}
                      </div>
                      <div className="flex">
                        <div>Орсон : </div>
                        <div className="pl-2 text-center">
                          {moment(checkDate).format("yyyy-MM-DD")}
                        </div>
                      </div>
                      <div className="flex">
                        <div>Дуусах : </div>
                        <div className="pl-2 text-center">
                          {moment(expireDate).format("yyyy-MM-DD")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-2 border-r w-1/6">
                  <div className="text-center font-bold">Торгууль</div>
                  <div
                    className={`font-bold text-center text-xs ${
                      penalty ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {penalty ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                  <div className="flex flex-col">{checkPenalty(listData)}</div>
                </div>
                <div className="flex flex-col p-2 border-r w-1/6">
                  <div className="text-center font-bold">Эрэн сурвалжлалт</div>
                  <div
                    className={`font-bold text-center text-xs ${
                      !asap ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {!asap ? "Хэвийн" : "Эрэн сурвалжлагдаж байгаа"}
                  </div>
                  <div className="text-center">{asapResponse}</div>
                </div>
                <div className="flex flex-col p-2 border-r w-1/6">
                  <div className="text-center font-bold">Согтуу жолооч</div>
                  <div
                    className={`font-bold text-center text-xs ${
                      !drunk ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {!drunk ? "Хэвийн" : "Бүртгэгдсэн"}
                  </div>
                  <div className="text-center">{drunkResponse}</div>
                </div>
              </div>
            );
          },
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        pagination={false}
        dataSource={list}
      />
    </div>
  );
};

export default Car;

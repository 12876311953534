import moment from "moment";
import React, { useState, useEffect } from "react";
import { FORMAT_DATE } from "../utils/const";
import Table from "../components/table";
import { Spin } from "antd";
import {
  AiFillTag,
  AiOutlineUser,
  AiOutlineNumber,
  AiFillCalendar,
  AiFillPlusSquare,
  AiOutlineMenu,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/provider";
import { mainApi } from "../utils/api";
const List = () => {
  const [list, setlist] = useState([]);

  const [loading, setloading] = useState(false);
  const [page, setpage] = useState(1);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const columns = [
    {
      icon: <AiFillTag size={20} color="#9291A5" />,
      title: "ID",
      field: "id",
      render: ({ data }) => {
        const { id } = data;
        return (
          <Link to={`${id}`} className="underline hover:cursor-pointer">
            {id}
          </Link>
        );
      },
      width: 100,
    },
    {
      icon: <AiOutlineUser size={20} color="#9291A5" />,
      title: "Эцэг/эх/-ийн нэр",
      field: "last_name",
    },

    {
      icon: <AiOutlineUser size={20} color="#9291A5" />,
      title: "Нэр",
      field: "first_name",
    },
    {
      icon: <AiOutlineNumber size={20} color="#9291A5" />,
      title: "Имайл",
      field: "email",
    },
    {
      icon: <AiOutlineMenu size={20} color="#9291A5" />,
      title: "Эрх",

      render: ({ data }) => {
        const { menu } = data;
        return (
          <div className="flex flex-wrap">
            {menu?.split(",")?.map((row, index) => {
              return (
                <div className="bg-blue-500 rounded text-white text-xs m-1 p-1">
                  {row}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      icon: <AiFillCalendar size={20} color="#9291A5" />,
      title: "Огноо",
      field: "data",
      render: ({ createdAt }) => {
        return <div>{moment(createdAt).format(FORMAT_DATE)}</div>;
      },
    },
  ];
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    setloading(true);
    try {
      const result = await mainApi({
        url: "/api/v1/users",
        method: "GET",
      });

      setlist(result?.data);
      setloading(false);
    } catch (e) {
      // if (e)

      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  return (
    <div className="flex  p-4 flex-col">
      <div className="flex flex-row  justify-between items-center">
        <div className="font-semibold text-2xl text-def-text">Хэрэглэгч</div>
        <div onClick={() => navigate("register")}>
          <AiFillPlusSquare size={30} color="#9291A5" />
        </div>
      </div>
      {loading ? (
        <div className="flex  h-64 items-center justify-center">
          <Spin />
        </div>
      ) : (
        <Table
          page={page}
          setpage={setpage}
          columns={columns}
          total={list?.count}
          list={list?.rows}
          isDrag={true}
          showTotal={true}
        />
      )}
    </div>
  );
};

export default List;

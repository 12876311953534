import { Table } from "antd";
import { useState } from "react";
const columns = [
  {
    title: "Зөрчил",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Нийслэл",
    children: [
      {
        title: "Зөрчилтэй",
        dataIndex: "ub_val",
        key: "ub_val",
        align: "center",
      },
      {
        title: "Зөрчилгүй",
        dataIndex: "ub_no_val",
        key: "ub_no_val",
        align: "center",
      },
    ],
  },
  {
    title: "Орон нутаг",
    align: "center",
    children: [
      {
        title: "Зөрчилтэй",
        dataIndex: "c_val",
        key: "c_val",
        align: "center",
      },
      {
        title: "Зөрчилгүй",
        dataIndex: "c_no_val",
        key: "c_no_val",
        align: "center",
      },
    ],
  },
];
const StatTable = ({ from, data, loading }) => {
  console.log("🚀 ~ StatTable ~ data:", from, data);

  return (
    <div className="flex flex-col w-full p-4 bg-white shadow h-fit rounded mt-2">
      <div className="text-base font-bold">{`${from} ${
        data?.total != null ? `/ нийт : ${data?.total} /` : ``
      }`}</div>
      <Table
        bordered
        size="middle"
        className="text-center mt-2"
        columns={columns}
        dataSource={data?.data}
        loading={loading}
        locale={{ emptyText: "Мэдээлэл байхгүй" }}
        pagination={false}
      />
    </div>
  );
};
export default StatTable;

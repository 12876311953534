import React, { useEffect, useState } from "react";
import { mainApi } from "../utils/api";
import moment from "moment";
import CustomList from "../components/table";
import { DatePicker } from "antd";
import { BsFillCalendarDateFill } from "react-icons/bs";
const { RangePicker } = DatePicker;
const columns = [
  {
    title: "Нэр",
    field: "manager_name",
    with: 400,
  },
  {
    title: "Нийт",
    field: "total",
  },
  {
    title: "Нийслэл",
    field: "UB",
  },
  {
    title: "Нийслэл давхцалгүй",
    field: "UB_DIS",
  },
  {
    title: "Орон нутаг",
    field: "CS",
  },
  {
    title: "Орон нутаг давхцалгүй",
    field: "CS_DIS",
  },
  {
    title: "Танигдаагүй",
    field: "BadPlatechars",
  },
  {
    title: "Дугааргүй",
    field: "not_plate_chars",
  },
];
const Data = () => {
  const [loading, setloading] = useState(false);
  const [list, setlist] = useState(null);
  const [date, setdate] = useState();
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    setloading(true);
    let find = "";
    if (date) {
      find = {
        field: "datetime",
        value: {
          start: {
            date: moment(date[0]).format("yyyy-MM-DD"),
            time: "00:00",
          },
          end: {
            date: moment(date[1]).format("yyyy-MM-DD"),
            time: "23:59:59",
          },
        },
      };
    } else {
      find = {
        field: "datetime",
        value: {
          end: {
            date: moment(new Date()).format("yyyy-MM-DD"),
            time: "23:59:59",
          },
          start: {
            date: moment(new Date()).format("yyyy-MM-DD"),
            time: "00:00:00",
          },
        },
      };
    }

    const result = await mainApi({
      url: `/api/v1/graph/manager`,
      method: "POST",
      data: find,
    });

    setlist(result?.data.rows);
    setloading(false);
  };
  return (
    <div>
      <div className="flex items-center">
        <div className="flex flex-row px-2.5 items-center mt-3">
          <BsFillCalendarDateFill
            size={20}
            color="#E15116"
            className="ml-0.5"
          />
          <RangePicker
            bordered={false}
            placeholder={["Эхлэх огноо", "Дуусах огноо"]}
            style={{ minWidth: 150 }}
            onChange={(date, dateString) => {
              setdate(dateString);
            }}
          />
        </div>
        <div
          onClick={() => fetch()}
          className="flex flex-row items-center bg-def-primary rounded p-2 hover:cursor-pointer h-8 mt-4"
        >
          <div className="text-sm w-24 text-center font-semibold text-white">
            Хайх
          </div>
        </div>
      </div>
      <CustomList
        total={list?.length}
        list={list}
        columns={columns}
        isDrag={true}
        showTotal={true}
        showPage={false}
      />
    </div>
  );
};

export default Data;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./layouts";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AuthProvider from "./utils/provider";
import Login from "./pages/Login";
import Notfound from "./pages/Notfound";

import { BASE_URL } from "./utils/const";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Navigate to="auth/login" />} />
          <Route path="auth">
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="app/*" element={<App />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </AuthProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

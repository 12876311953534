import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/provider";
import { GetFromStorage } from "../utils/storage";
import { CiDatabase } from "react-icons/ci";
import { GrServices } from "react-icons/gr";
import { FcStatistics } from "react-icons/fc";
import { IoCarSportOutline } from "react-icons/io5";

import routes from "./routes";
import {
  MdOutlineList,
  MdAppSettingsAlt,
  MdFormatListNumbered,
} from "react-icons/md";
import { CiMobile4 } from "react-icons/ci";
import { RiDashboardLine, RiRoadMapLine } from "react-icons/ri";
import { AiOutlineUser, AiFillAndroid } from "react-icons/ai";
import { RxExit } from "react-icons/rx";
import Swal from "sweetalert2";

const getMenu = (name) => {
  switch (name) {
    case "dashboard":
      return { icon: <RiDashboardLine size={20} />, url: "dashboard" };
    case "device":
      return { icon: <CiMobile4 size={20} />, url: "device" };
    case "countered":
      return { icon: <MdFormatListNumbered size={20} />, url: "countered" };
    case "tag":
      return { icon: <MdOutlineList size={20} />, url: "tag" };
    case "manager":
      return { icon: <MdAppSettingsAlt size={20} />, url: "manager" };
    case "user":
      return { icon: <AiOutlineUser size={20} />, url: "user" };
    case "map":
      return { icon: <RiRoadMapLine size={20} />, url: "map" };
    case "app":
      return { icon: <AiFillAndroid size={20} />, url: "app" };
    case "managerdata":
      return { icon: <CiDatabase size={20} />, url: "managerdata" };
    case "statistic":
      return { icon: <FcStatistics size={20} />, url: "statistic" };
    case "servicelog":
      return { icon: <GrServices size={20} />, url: "servicelog" };
    case "car":
      return { icon: <IoCarSportOutline size={20} />, url: "car" };
    default:
      return null;
  }
};
const Index = () => {
  const { user, logout } = useAuth();
  const [menu, setmenu] = useState(null);
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const u = GetFromStorage("user");
    const obj = JSON.parse(u);

    const cmenu = obj?.menu?.split(",");
    setmenu(cmenu);
    if (!u) navigate("/auth/login");
    setloading(false);
  }, []);

  return (
    <div className="flex flex-row bg-gray-100">
      {loading && menu == null ? (
        <div className="flex  justify-center items-center h-64 ">
          <Spin />
        </div>
      ) : (
        <div className="flex flex-row w-full">
          <div className="flex flex-col mt h-screen bg-white border-r">
            <div className="flex flex-row justify-center py-3 align-middle items-center w-16 bg-white ">
              <div className="flex flex-col justify-center ">
                {/* <img
                // src="../logo512.png"
                style={{ width: 40, height: 40, marginTop: 5 }}
                alt=""
              /> */}
                <div>
                  {menu.map((row, index) => {
                    const m = getMenu(row);
                    if (m != null) {
                      const { url, icon } = m;
                      return (
                        <NavLink
                          to={url}
                          key={index}
                          className={({ isActive }) =>
                            `${
                              isActive
                                ? `bg-def-primary text-white rounded-sm`
                                : `text-black`
                            } flex flex-row align-middle items-center p-4 hover:bg-def-primary hover:text-white hover:rounded-sm`
                          }
                        >
                          {icon}
                          {/* <div className="text-lg pl-3">{label}</div> */}
                        </NavLink>
                      );
                    }
                  })}
                  <button
                    onClick={() =>
                      Swal.fire({
                        title: "Системээс гарах уу.?",
                        showDenyButton: true,
                        confirmButtonText: "Тийм",
                        denyButtonText: `Үгүй`,
                      }).then(async (result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                          logout();
                        }
                      })
                    }
                    className=" flex flex-row align-middle items-center p-4 hover:bg-def-primary hover:text-white hover:rounded-sm hover:cursor-pointer"
                  >
                    <RxExit />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-5 w-full">
            <Routes>
              {routes?.map(({ path, element }, index) => {
                return (
                  <Route
                    key={index}
                    index={index === 0}
                    path={path}
                    element={element}
                  />
                );
              })}
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;

import UB from "./components/ub";
import Total from "./components/total";
import CS from "./components/cs";
import Tag from "./components/tag";
const Counts = ({ stat, tags }) => {
  return (
    <div className="flex flex-col w-full">
      <Total stat={stat} />
      <UB stat={stat} />
      <CS stat={stat} />
      <Tag list={tags} />
    </div>
  );
};
export default Counts;

import { CgArrowsExpandUpRight } from "react-icons/cg";
import Item from "./item";
import { Spin } from "antd";
const Tag = ({ list }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        <div className="font-bold text-lg">Сүүлд уншигдсан таг</div>
      </div>
      <div className="flex flex-wrap">
        {list ? (
          list.map((row, index) => {
            return <Item data={row} key={index} />;
          })
        ) : (
          <div className="flex w-full bg-white p-3 rounded shadow justify-center items-center h-80">
            <Spin size="small" />
          </div>
        )}
      </div>
    </div>
  );
};
export default Tag;

import { socket } from "../utils/socket";
import { useEffect, useState } from "react";
import Devices from "./devices";
import Violation from "./violation";
import Info from "./components/info";
import Counts from "./counts";
import Tag from "./components/tag";
import ViolationUniq from "./ViolationUniq";

import { Tooltip, Button } from "antd";
import objectPath from "object-path";
import { TbDeviceDesktopX, TbDeviceImacCheck } from "react-icons/tb";
import Bars from "./components/bar";
import { socketStat } from "../utils/socketStat";
import axios from "axios";
import moment from "moment";

const Index = () => {
  const [stat, setstat] = useState(null);
  const [vioalation, setvioalation] = useState(null);
  const [list, setlist] = useState(null);
  const [tags, settags] = useState(null);
  const [date, setdate] = useState(new Date());
  const [loading, setloading] = useState(false);
  const fetchSocket = () => {
    try {
      socket.on("device-status", (res) => {
        const online = res?.managers?.filter(
          (row) => row?.status.toString() === "true"
        );
        const offline = res?.managers?.filter(
          (row) => row?.status.toString() === "false"
        );

        setlist({
          data: res.managers,
          total: res.managers.length,
          online: online.length,
          offline: offline.length,
        });
      });

      socket.on("stats", (res) => {
        // console.log("🚀 ~ file: index.js:35 ~ socket.on ~ res:", res.stats);
        setstat(res?.stats);
      });

      socket.on("tag-events", (res) => {
        settags([res]);
      });
    } catch (err) {}
  };

  useEffect(() => {
    fetchSocket();
  }, []);

  useEffect(() => {
    fetch();
  }, [date]);
  const fetch = async () => {
    try {
      console.log(moment(date).format("YYYY-MM-DD"));
      setloading(true);
      const result = await axios({
        url: `https://vil.odt.mn/service/stats`,
        auth: {
          username: "odt",
          password: "odt123456",
        },
        method: "POST",
        data: {
          date: moment(date).format("YYYY-MM-DD"),
        },
      });
      console.log("🚀 ~ fetch ~ result:", result.data);
      setvioalation(result.data);
      setloading(false);
    } catch (err) {
      setloading(false);
      console.log("ServiceLog", err);
    }
  };
  return (
    <div className="flex w-full flex-col">
      <div className="flex">
        <Devices stat={stat?.systemInfo} device={list} />
      </div>
      <div className="flex w-full">
        <div className="flex flex-col w-1/2">
          <Counts stat={stat} tags={tags} />
          <Bars />
        </div>
        <div className="flex flex-col w-1/2">
          {/* <Stats data={stat?.systemInfo} /> */}
          {/* <Device data={list} /> */}
          <Violation
            data={vioalation}
            fetch={fetch}
            loading={loading}
            setdate={setdate}
            date={date}
          />
          <ViolationUniq data={vioalation} fetch={fetch} loading={loading} />
        </div>
      </div>
    </div>
  );
};
export default Index;

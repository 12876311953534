import React, { useState, useEffect } from "react";
import { Input, Select, DatePicker, Modal, Switch, Spin } from "antd";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsFillGridFill, BsFillCalendarDateFill } from "react-icons/bs";
import {
  AiOutlineUnorderedList,
  AiFillCar,
  AiOutlineCheckSquare,
} from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import {
  FORMAT_DATE,
  check_list,
  distinct_list,
  list_district,
} from "../utils/const";
import { RiRouterFill } from "react-icons/ri";
import { CiMobile4, CiCalendarDate } from "react-icons/ci";
import { GrDirections } from "react-icons/gr";
import { BiTime } from "react-icons/bi";
import isEmpty from "is-empty";
import {
  AiFillTag,
  AiOutlineRadarChart,
  AiOutlineNumber,
} from "react-icons/ai";
import CustomList from "./components/list";
import Grid from "./components/grid";
import moment from "moment";
import { useAuth } from "../utils/provider";
import { BiRfid } from "react-icons/bi";
import CustomSelect from "../components/CustomSelect";
import { mainApi } from "../utils/api";

const { RangePicker } = DatePicker;
const List = () => {
  const [loading, setloading] = useState(false);
  const [district, setdistrict] = useState();
  const [platenumber, setplatenumber] = useState(null);
  const [epc, setepc] = useState(null);
  const [tid, settid] = useState(null);
  const [name, setname] = useState();
  const [has, sethas] = useState(0);
  const [direction, setdirection] = useState();
  const [type, settype] = useState(1);
  const [date, setdate] = useState();
  const [total, settotal] = useState(0);
  const [list, setlist] = useState();
  const [pages, setpages] = useState(0);
  const [open, setopen] = useState(false);
  const [car, setcar] = useState(null);
  const [data, setdata] = useState({});
  const [page, setpage] = useState(1);
  const [violation, setviolation] = useState(null);
  const [carloading, setcarloading] = useState(false);
  const [unique, setunique] = useState(0);
  const [manager, setmanager] = useState();
  // const [refreshInterval, setRefreshInterval] = useState();

  const { logout } = useAuth();
  const columns = [
    {
      icon: <AiFillTag size={20} color="#9291A5" />,
      title: "EPC",
      field: "epc_num",
      width: 250,
      render: ({ data }) => {
        return (
          <div
            className="underline hover:cursor-pointer"
            onClick={() => {
              setdata(data);
              setopen(true);
            }}
          >
            <div>{`EPC : ${data?.epc_num}`}</div>
            <div>{`TID : ${data?.tid_num}`}</div>
          </div>
        );
      },
    },

    {
      icon: <AiFillCar size={20} color="#9291A5" />,
      title: "Улсын дугаар",
      field: "plate_chars",
      width: 120,
    },

    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Антине",
      field: "antenna_num",
      width: 70,
    },

    {
      icon: <CiMobile4 size={20} color="#9291A5" />,
      title: "Төхөөрөмж нэр",
      field: "manager.name",
      width: 180,
    },
    {
      icon: <GrDirections size={20} color="#9291A5" />,
      title: "Төхөөрөмж чиглэл",
      field: "manager.direction",
      width: 250,
    },
    {
      icon: <BiTime size={20} color="#9291A5" />,
      title: "Уншигпсан огноо",
      field: "time",
      with: 120,
      render: ({ data }) => {
        return (
          <div>
            <div>{`${data?.data} ${data?.time}`}</div>
          </div>
        );
      },
    },
    {
      icon: <AiOutlineRadarChart size={20} color="#9291A5" />,
      title: "Илгээгдсэн огноо",
      field: "rssi_level",
      width: 180,
      render: ({ data }) => {
        return (
          <div>
            <div>{`${moment(new Date(data?.createdAt)).format(
              `YYYY-MM-DD HH:mm:ss`
            )}`}</div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    fetch();
  }, [page]);

  const fetch = async () => {
    setloading(true);
    const find = [];
    let url = `/api/v1/searchrfid?page=${page}`;
    if (unique == 1) {
      url = `/api/v1/searchdistinc?page=${page}`;
    }
    if (date) {
      find.push({
        field: "datetime",
        value: {
          start: {
            date: moment(date[0]).format("yyyy-MM-DD"),
            time: "00:00",
          },
          end: {
            date: moment(date[1]).format("yyyy-MM-DD"),
            time: "23:59:59",
          },
        },
      });
    }
    if (platenumber) {
      find.push({
        field: "plate_chars",
        value: platenumber.toUpperCase(),
      });
    }
    if (epc) {
      find.push({
        field: "epc_num",
        value: epc.toUpperCase(),
      });
    }
    if (tid) {
      find.push({
        field: "tid_num",
        value: tid.toUpperCase(),
      });
    }
    if (manager) {
      find.push({
        field: "uuid",
        value: manager,
      });
    }

    if (has == 1) {
      find.push({
        field: "plate_chars",
        value: "nonull",
      });
    } else if (has == 2) {
      find.push({
        field: "plate_chars",
        value: null,
      });
    } else {
      if (isEmpty(find))
        find.push({
          field: "datetime",
          value: {
            start: {
              date: moment(new Date().setDate(-1)).format("yyyy-MM-DD"),
              time: "00:00",
            },
            end: {
              date: moment(new Date()).format("yyyy-MM-DD"),
              time: "23:59:59",
            },
          },
        });
    }

    try {
      setloading(true);
      console.time("l");
      const result = await mainApi({
        method: "POST",
        url: url,
        data: find,
      });

      setlist(result?.data.rows);
      settotal(result?.data?.count);
      // setpages(result?.data?.pages);
      console.timeEnd("l");
      setloading(false);
    } catch (e) {
      // if (e)

      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  useEffect(() => {
    data != null && fetchCar();
  }, [data]);

  const fetchCar = async () => {
    try {
      setcarloading(true);

      const result = await mainApi({
        url: `/api/v1/rfid/rfidtag/${data?.epc_num}`,
        method: "GET",
      });

      setcar(result.data);
      setcarloading(false);
    } catch (e) {
      // if (e)

      if (e.response?.status == 401) logout();
      setcarloading(false);
    }
  };
  return (
    <div className="flex  p-4 flex-col ">
      <div className="flex flex-row  justify-between">
        <div className="font-semibold text-2xl text-def-text">RFID</div>
        <div className="flex flex-row items-center border-2 border-def-background rounded p-2">
          <div className="px-2 border-r-2" onClick={() => settype(1)}>
            <AiOutlineUnorderedList
              size={20}
              className={`hover:text-def-primary hover:cursor-pointer ${
                type == 1 && `text-def-primary`
              }`}
            />
          </div>
          <div className="px-2" onClick={() => settype(2)}>
            <BsFillGridFill
              size={17}
              className={`hover:text-def-primary hover:cursor-pointer  ${
                type == 2 && `text-def-primary`
              }`}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-row py-2.5 border-t-2 border-b-2 border-b-def-background bg-white mt-4  justify-between">
          <div className="flex flex-row">
            {/* <div className="flex flex-row px-2.5 items-center">
              <MdLocationOn size={25} color="#E15116" />
              <Select
                placeholder="Байршлаар хайх"
                className="w-40"
                bordered={false}
                onChange={(value) => {
                  setdistrict(value);
                }}
                options={list_district}
              />
            </div> */}
            <div className="flex flex-col">
              <div className="flex flex-row px-2.5 items-center">
                <AiFillTag size={30} color="#E15116" />
                <Input
                  onChange={(e) => {
                    setepc(e.target.value);
                  }}
                  value={epc}
                  className="border-none"
                  style={{ minWidth: 220 }}
                  placeholder="EPC хайх"
                />
              </div>
              <div className="flex flex-row px-2.5 items-center mt-3">
                <AiFillTag size={30} color="#E15116" />
                <Input
                  onChange={(e) => {
                    settid(e.target.value);
                  }}
                  value={tid}
                  style={{ minWidth: 220 }}
                  className="border-none"
                  placeholder="TiD хайх"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row px-2.5 items-center">
                <AiOutlineCheckSquare size={25} color="#E15116" />
                <Select
                  placeholder="Бүртгэлээр хайх"
                  className="w-40"
                  bordered={false}
                  onChange={(value) => {
                    sethas(value);
                  }}
                  options={check_list}
                />
              </div>
              <div className="flex flex-row px-2.5 items-center mt-3">
                <AiOutlineOrderedList size={25} color="#E15116" />
                <Select
                  placeholder="Давхцалын төлөв"
                  className="w-40"
                  bordered={false}
                  onChange={(value) => {
                    setunique(value);
                  }}
                  options={distinct_list}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row px-2.5 items-center">
                <AiFillCar size={25} color="#E15116" />
                <Input
                  onChange={(e) => {
                    setplatenumber(e.target.value);
                  }}
                  value={platenumber}
                  className="border-none"
                  placeholder="Улсын дугаараар хайх"
                />
              </div>
              <div className="flex flex-row px-2.5 items-center mt-3">
                <BsFillCalendarDateFill
                  size={20}
                  color="#E15116"
                  className="ml-0.5"
                />
                <RangePicker
                  bordered={false}
                  placeholder={["Эхлэх огноо", "Дуусах огноо"]}
                  style={{ minWidth: 150 }}
                  onChange={(date, dateString) => {
                    setdate(dateString);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row px-2.5 items-center">
                <BiRfid size={25} color="#E15116" />
                <CustomSelect
                  placeholder="Төхөөрөмжөөр хайх"
                  bordered={false}
                  set={setmanager}
                  axio={`/api/v1/manager?limit=-1`}
                  method={"GET"}
                />
              </div>
            </div>
          </div>
          <div
            onClick={() => fetch()}
            className="flex flex-row items-center bg-def-primary rounded p-2 hover:cursor-pointer"
          >
            <div className="text-sm w-24 text-center font-semibold text-white">
              Хайх
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center h-full items-center">
            <img src="../magnify.svg" />
          </div>
        ) : type == 1 ? (
          loading ? (
            <div className="flex justify-center items-center">
              <Spin size="large" />
            </div>
          ) : (
            <CustomList
              page={page}
              setpage={setpage}
              total={total}
              list={list}
              columns={columns}
              pages={pages}
              hidePage={false}
              showPage
            />
          )
        ) : (
          <Grid page={page} setpage={setpage} total={total} list={list} />
        )}
      </div>
      <Modal
        title="Дэлгэрэнгүй мэдээлэл"
        open={open}
        onOk={() => setopen(false)}
        onCancel={() => setopen(false)}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
        className=""
        width={1200}
        footer={false}
        confirmLoading={carloading}
      >
        <div className="my-3 shadow flex flex-row  rounded p-4">
          <img
            src="../../car.png"
            width={130}
            height={120}
            className="border-2 rounded p-2"
          />
          <div className="flex flex-row">
            <div className="flex flex-col w-1/3 px-3">
              <div className="flex flex-row justify-between items-center border-b-2">
                <div className="text-base font-semibold">
                  Төхөөрөмжийн мэдээлэл
                </div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">EPC</div>
                <div className="text-sm font-semibold">{data?.epc_num}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">TID</div>
                <div className="text-sm font-semibold">{data?.tid_num}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Уншигдсан огноо</div>
                <div className="text-sm font-semibold">
                  {moment(data?.createdAt).format(FORMAT_DATE)}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Төхөөрөмжийн нэр</div>
                <div className="text-sm font-semibold">{data?.name}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Төхөөрөмжийн чиглэл</div>
                <div className="text-sm font-semibold">{data?.direction}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Антине</div>
                <div className="text-sm font-semibold">{data?.antenna_num}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">Уншигдсан тоо</div>
                <div className="text-sm font-semibold">{data?.count_num}</div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="text-sm">RSSI түвшин</div>
                <div className="text-sm font-semibold">{data?.rssi_level}</div>
              </div>
            </div>
            <div className="flex flex-col w-1/3 px-3">
              <div className="flex flex-row justify-between items-center border-b-2">
                <div className="text-base font-semibold">
                  Тээврийн хэрэгслийн мэдээлэл
                </div>
              </div>
              {carloading ? (
                <div className="flex justify-center items-center mt-5">
                  <img width={60} height={60} src="../../magnify.svg" />
                </div>
              ) : car === null ? (
                <div className="flex justify-center mt-4 font-bold text-red-500">
                  <div>Мэдэээлэл олдсонгүй</div>
                </div>
              ) : (
                <>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Улсын дугаар</div>
                    <div className="text-sm font-semibold">{car?.plateNo}</div>
                  </div>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Марк</div>
                    <div className="text-sm font-semibold">{car?.markName}</div>
                  </div>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Модел</div>
                    <div className="text-sm font-semibold">
                      {car?.modelName}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Зориулалт</div>
                    <div className="text-sm font-semibold">
                      {car?.purposeName}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Өнгө</div>
                    <div className="text-sm font-semibold">
                      {car?.colorName}
                    </div>
                  </div>

                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Загвар</div>
                    <div className="text-sm font-semibold">
                      {car?.typeNameMon}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Үйлдвэрлэсэн огноо</div>
                    <div className="text-sm font-semibold">
                      {car?.buildYear}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col w-1/3 px-3">
              <div className="flex flex-row justify-between items-center border-b-2">
                <div className="text-base font-semibold">Зөрчлийн мэдээлэл</div>
              </div>
              {violation == null ? (
                <div className="flex justify-center mt-4 font-bold text-red-500">
                  <div>Мэдэээлэл олдсонгүй</div>
                </div>
              ) : (
                <div>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Баталгаажсан</div>
                    <div className="text-sm font-semibold">
                      <FcApproval />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Хурд</div>
                    <div className="text-sm font-semibold">{99}</div>
                  </div>
                  <div className="flex flex-row justify-between items-center mt-2">
                    <div className="text-sm">Даатгал</div>
                    <div className="text-sm font-semibold">{"Тийм"}</div>
                  </div>
                  <div className="flex flex-row justify-between mt-2 items-start">
                    <div className="text-sm">Зөрчил</div>
                    <div className="flex flex-col">
                      <div className="text-xs font-semibold bg-def-primary rounded-full p-1 px-4 text-white">
                        Хурд хэтэрсэн
                      </div>
                      <div className="text-xs font-semibold bg-red-500 rounded-full mt-1 p-1 px-4 text-white">
                        Оншилгоо
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default List;

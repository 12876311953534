import objectPath from "object-path";
import Line from "./components/lineCount";
import { Spin } from "antd";
import { LuRefreshCcw } from "react-icons/lu";
import axios from "axios";
import { useState, useEffect } from "react";
const ViolationUniq = () => {
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(null);
  const [refresh, setrefresh] = useState(false);
  const fetch = async () => {
    try {
      setloading(true);
      const result = await axios({
        url: `https://vil.odt.mn/service/distinct`,
        auth: {
          username: "odt",
          password: "odt123456",
        },
        method: "GET",
      });
      console.log("🚀 ~ fetch=", result.data);
      setdata(result.data);
      setloading(false);
    } catch (err) {
      setloading(false);
      console.log("ServiceLog", err);
    }
  };
  useEffect(() => {
    fetch();
  }, [refresh]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center pr-4">
          <div className="font-bold text-lg">Тоо мэдээ /Давхцалгүй/</div>
          <LuRefreshCcw
            onClick={() => setrefresh(!refresh)}
            className="hover:cursor-pointer"
          />
        </div>
        <div className="flex flex-wrap">
          <Line
            text={
              loading == false ? (
                ` ${data?.total
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Нийт машин`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.ub
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Нийт машин /Нийслэл/`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.country
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Нийт машин /Орон нутаг/`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.tax
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Татвар төлсөн`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.notax
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Татвар төлөөгүй`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.inspection
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Үзлэгт тэнцсэн`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.noinspection
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Үзлэгт тэнцээгүй`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.penalty
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Торгуультай`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.nopenalty
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Торгуульгүй`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.insurance
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Даатгалтай`}
          />
          <Line
            text={
              loading == false ? (
                ` ${data?.noinsurance
                  ?.toString()
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`
              ) : (
                <Spin size="small" />
              )
            }
            t={`Даатгалгүй`}
          />
        </div>
      </div>
    </div>
  );
};
export default ViolationUniq;

import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { SaveToStorage, RemoveFromStorage } from "./storage";
import { mainApi } from "./api";

const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  const login = async (instance) => {
    setInstance(instance.token, instance);
    navigate("/app/dashboard");
  };

  const logout = () => {
    Cookies.remove("token");
    RemoveFromStorage("token");
    Cookies.remove("user");
    // delete mainApi.defaults;
    navigate("/");
  };

  const setInstance = (token, user) => {
    if (!token) {
      Cookies.remove("token");
      RemoveFromStorage("token");
      Cookies.remove("user");
      delete mainApi.defaults.headers;
    } else {
      Cookies.set("token", token);
      SaveToStorage("token", token);
      Cookies.set("user", user && JSON.stringify(user));
      mainApi.defaults.headers = {
        "x-access-token": token,
      };
    }

    setUser(user);
  };

  const getUserInstance = () => {
    const storedToken = Cookies.get("token");

    const storedUser = Cookies.get("user");

    if (storedToken && storedUser && storedUser !== "undefined") {
      setInstance(storedToken, JSON.parse(storedUser));
    }
  };

  useEffect(() => {
    getUserInstance();
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;

import L from "leaflet";

const Icon = new L.Icon({
  iconUrl: require("./pin.png"),
  iconRetinaUrl: require("./pin.png"),
  iconSize: new L.Point(25, 25),
  className: "leaflet-div-icon",
});

export default Icon;

import { Spin, Select } from "antd";
import objectPath from "object-path";
import React, { useState, useEffect } from "react";
import { mainApi } from "../utils/api";

const CustomSelect = ({ placeholder, bordered, set, axio, method }) => {
  const [list, setlist] = useState();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    axio && fetch();
  }, []);
  const fetch = async () => {
    try {
      setloading(true);
      const res = await mainApi({
        url: axio,
        method: method,
      });

      const temp = [];
      res.data.rows?.map((row) => {
        const name = objectPath.get(row, "name");
        const uuid = objectPath.get(row, "uuid");
        temp.push({
          label: name,
          value: uuid,
        });
      });
      setlist(temp);
      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };
  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <Select
          showSearch={true}
          placeholder={placeholder}
          bordered={bordered}
          filterOption={(input, option) =>
            (option?.label.toUpperCase() ?? "").includes(input.toUpperCase())
          }
          onChange={(value) => {
            set(value);
          }}
          options={list}
        />
      )}
    </div>
  );
};

export default CustomSelect;

import objectPath from "object-path";
import Line from "./lineCount";
import { PiCpuThin } from "react-icons/pi";
import { RiRfidFill } from "react-icons/ri";
import { MdCalendarMonth } from "react-icons/md";
import { Spin } from "antd";

const Stats = ({ stat }) => {
  const UB_REG = objectPath.get(stat, "UB_REG");
  const tagindb = objectPath.get(stat, "tagindb");
  const UB_CURRENT = objectPath.get(stat, "total.UB-CURRENT-DAY");
  const UB_CURRENT_DIS = objectPath.get(stat, "total.UB-CURRENT-DAY-DISTINC");

  const UB_MONTH = objectPath.get(stat, "total.UB-CURRENT-MONTH");
  const UB_MONTH_DIS = objectPath.get(stat, "total.UB-CURRENT-MONTH-DISTINC");

  return (
    <div className="flex flex-col">
      <div className="font-bold text-lg">Нийслэлийн дугаар</div>
      <div className="flex flex-wrap">
        <Line
          color={"bg-green-400"}
          icon={<RiRfidFill size={20} />}
          text={
            UB_REG ? (
              ` ${UB_REG?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )}`
            ) : (
              <Spin size="small" />
            )
          }
          t={`Нийт бүртгэгдсэн`}
        />
        <Line
          color={"bg-green-400"}
          icon={<PiCpuThin size={30} />}
          text={
            UB_CURRENT_DIS ? (
              `${UB_CURRENT?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )} / 
             ${UB_CURRENT_DIS?.toString()?.replace(
               /(\d)(?=(\d\d\d)+(?!\d))/g,
               "$1,"
             )}`
            ) : (
              <Spin size="small" />
            )
          }
          t={"Өнөөдөр уншигдсан"}
        />

        <Line
          icon={<MdCalendarMonth size={30} />}
          text={
            UB_MONTH_DIS ? (
              `${UB_MONTH?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )} / 
              ${UB_MONTH_DIS?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )}`
            ) : (
              <Spin size="small" />
            )
          }
          t={`Энэ сард уншигдсан`}
        />
      </div>
    </div>
  );
};
export default Stats;

const Line = ({ icon, text, t, percent, color }) => {
  return (
    <div className="flex p-2 h-28 w-1/3">
      <div className="flex flex-col bg-white p-4 rounded shadow justify-center w-full">
        <div className="flex flex-col justify-center align-baseline">
          <div className="text-xl text-center">{text}</div>
          <div className="font-bold text-xs text-center mt">{t}</div>
        </div>
      </div>
    </div>
  );
};
export default Line;

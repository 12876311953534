import React, { useState, useEffect } from "react";
import { TiLocationArrow } from "react-icons/ti";
import { HiDeviceMobile } from "react-icons/hi";
import { MdLocationOn, MdLocationPin } from "react-icons/md";
import { BsFillGridFill } from "react-icons/bs";
import { AiOutlineNumber, AiOutlineArrowRight } from "react-icons/ai";
import { Input, Select, DatePicker } from "antd";
import CustomList from "../components/table";
import { FORMAT_DATE, list_district, TEST_SETTING } from "../utils/const";
import { CiMobile4, CiCalendarDate } from "react-icons/ci";
import { GrDirections } from "react-icons/gr";
import moment from "moment";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/provider";
import { mainApi } from "../utils/api";

const columns = [
  {
    icon: <AiOutlineNumber size={20} color="#9291A5" />,
    title: "ID",
    field: "id",
    width: 100,
    render: ({ data }) => {
      const { id } = data;
      return (
        <Link to={`${id}`}>
          <div className="underline hover:text-def-white">{id}</div>
        </Link>
      );
    },
  },
  {
    icon: <TiLocationArrow size={20} color="#9291A5" />,
    title: "Нэр",
    field: "name",
  },
  {
    icon: <GrDirections size={20} color="#9291A5" />,
    title: "Чиглэл",
    field: "direction",
  },
  {
    icon: <GrDirections size={20} color="#9291A5" />,
    title: "Дүүрэг",
    field: "district",
  },
  {
    icon: <GrDirections size={20} color="#9291A5" />,
    title: "Хороо",
    field: "horoo",
  },
  {
    icon: <MdLocationPin size={20} color="#9291A5" />,
    title: "Координат",
    field: "coordinate",
  },
  {
    icon: <CiCalendarDate size={20} color="#9291A5" />,
    title: "Бүртгэгдсэн огноо",
    field: "createdAt",
    render: ({ data }) => {
      const { createdAt } = data;
      return <div>{moment(createdAt).format(FORMAT_DATE)}</div>;
    },
  },
];
const List = () => {
  const [loading, setloading] = useState(false);
  const [district, setdistrict] = useState();
  const [name, setname] = useState();
  const [direction, setdirection] = useState();
  const [total, settotal] = useState(0);
  const [list, setlist] = useState();
  const [page, setpage] = useState(1);
  const { logout } = useAuth();
  useEffect(() => {
    fetch();
  }, [name, district, direction, page]);
  const fetch = async () => {
    setloading(true);
    try {
      const result = await mainApi({
        url: `/api/v1/manager?page=${page}`,
        method: "GET",
      });

      settotal(result?.data?.count);
      setlist(result?.data?.rows);
      setloading(false);
    } catch (e) {
      // if (e)

      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  return (
    <div className="flex  p-4 flex-col">
      <div className="flex flex-row  justify-between ">
        <div className="font-semibold text-2xl text-def-text">RFID уншигч</div>
      </div>
      <div>
        {/* <div className="flex flex-row py-2.5 border-t-2 border-b-2 border-b-def-background mt-4  justify-between">
          <div className="flex flex-row">
            <div className="flex flex-row px-2.5 items-center">
              <MdLocationOn size={25} color="#E15116" />
              <Select
                placeholder="Байршлаар хайх"
                className="w-40"
                bordered={false}
                onChange={(value) => {
                  setdistrict(value);
                }}
                options={list_district}
              />
            </div>
            <div className="flex flex-row px-2.5 items-center">
              <TiLocationArrow size={30} color="#E15116" />
              <Input
                onChange={(e) => {
                  setdirection(e.target.value);
                }}
                value={direction}
                className="border-none"
                placeholder="Чиглэлээр хайх"
              />
            </div>

            <div className="flex flex-row px-2.5 items-center">
              <HiDeviceMobile size={25} color="#E15116" />
              <Input
                onChange={(e) => {
                  setname(e.target.value);
                }}
                value={name}
                className="border-none"
                placeholder="Нэрээр хайх"
              />
            </div>
          </div>
        </div> */}
        {loading ? (
          <div className="flex justify-center h-full items-center">
            <img src="../magnify.svg" />
          </div>
        ) : (
          <CustomList
            page={page}
            setpage={setpage}
            total={total}
            list={list}
            columns={columns}
            isDrag={true}
            showTotal={true}
            showPage={true}
          />
        )}
      </div>
    </div>
  );
};

export default List;

import React from "react";
import Item from "./item";
import { Pagination } from "antd";
const grid = ({ list, columns, total, page, setpage }) => {
  return (
    <div className="flex flex-col justify-center">
      <div className="flex justify-end items-center my-4">
        <Pagination
          simple
          defaultCurrent={page}
          onChange={(e) => setpage(e)}
          pageSize={30}
          total={total}
        />
      </div>

      {list?.map((row, index) => {
        return <Item key={index} total={total} row={row} />;
      })}
    </div>
  );
};

export default grid;

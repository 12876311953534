import React, { useState, useEffect } from "react";
import { Input, Select, DatePicker, Modal, Switch, Spin } from "antd";
import { AiOutlineOrderedList } from "react-icons/ai";
import { BsFillGridFill, BsFillCalendarDateFill } from "react-icons/bs";
import {
  AiOutlineUnorderedList,
  AiFillCar,
  AiOutlineCheckSquare,
} from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import {
  FORMAT_DATE,
  check_list,
  distinct_list,
  list_district,
} from "../utils/const";
import { RiRouterFill } from "react-icons/ri";
import { CiMobile4, CiCalendarDate } from "react-icons/ci";
import { GrDirections } from "react-icons/gr";
import { BiTime } from "react-icons/bi";
import isEmpty from "is-empty";
import {
  AiFillTag,
  AiOutlineRadarChart,
  AiOutlineNumber,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import CustomList from "./components/list";
import Grid from "./components/grid";
import moment from "moment";
import { useAuth } from "../utils/provider";
import { socket } from "../utils/socket";
import { mainApi } from "../utils/api";
const { RangePicker } = DatePicker;
const List = () => {
  const [loading, setloading] = useState(false);
  const [district, setdistrict] = useState();
  const [platenumber, setplatenumber] = useState(null);
  const [epc, setepc] = useState(null);
  const [tid, settid] = useState(null);
  const [name, setname] = useState();
  const [has, sethas] = useState(0);
  const [direction, setdirection] = useState();
  const [type, settype] = useState(1);
  const [date, setdate] = useState();
  const [total, settotal] = useState(0);
  const [list, setlist] = useState();
  const [pages, setpages] = useState(0);
  const [open, setopen] = useState(false);
  const [car, setcar] = useState(null);
  const [data, setdata] = useState({});
  const [page, setpage] = useState(1);
  const [violation, setviolation] = useState(null);
  const [carloading, setcarloading] = useState(false);
  const [unique, setunique] = useState(0);
  // const [refreshInterval, setRefreshInterval] = useState();

  const { logout } = useAuth();
  const columns = [
    {
      icon: <AiFillCar size={20} color="#9291A5" />,
      title: "Төхөөрөмжийн нэр",
      field: "manager_name",
      width: 200,
    },

    {
      icon: <AiOutlineNumber size={20} color="#9291A5" />,
      title: "Уншигдсан тоо",
      field: "count",
      width: 150,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Төхөөрөмжийн ID",
      field: "manager_uuid",
      width: 350,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан",
      field: "count",
      width: 120,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан /Нийслэл/",
      field: "ub",
      width: 165,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан /Орон нутаг/",
      field: "cs",
      width: 180,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан /Танигдаагүй/",
      field: "BadPlatechars",
      width: 180,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан /Дугааргүй/",
      field: "not_plate",
      width: 180,
    },
  ];
  const columns_distinc = [
    {
      icon: <AiFillCar size={20} color="#9291A5" />,
      title: "Төхөөрөмжийн нэр",
      field: "manager_name",
      width: 200,
    },

    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Төхөөрөмжийн ID",
      field: "manager_uuid",
      width: 350,
    },

    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан /Нийслэл/",
      field: "UB",
      width: 165,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан /Орон нутаг/",
      field: "CS",
      width: 180,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан /Танигдаагүй/",
      field: "BadPlatechars",
      width: 180,
    },
    {
      icon: <RiRouterFill size={20} color="#9291A5" />,
      title: "Нийт уншсан /Дугааргүй/",
      field: "not_plate_chars",
      width: 180,
    },
  ];
  useEffect(() => {
    fetch();
  }, [page]);

  const fetch = async () => {
    setloading(true);
    let find = {};
    let url = `/api/v1/countereventbymanager`;
    if (unique == 0) url = `/api/v1/countereventbymanager`;
    else url = `/api/v1/countereventbymanager/distinc/`;
    // if (platenumber) {
    //   find = {
    //     ...find,
    //     field: "plate_chars",
    //     value: platenumber.toUpperCase(),
    //   };
    // }
    // if (epc) {
    //   find = {
    //     ...find,
    //     field: "epc_num",
    //     value: epc.toUpperCase(),
    //   };
    // }
    // if (tid) {
    //   find = {
    //     ...find,
    //     field: "tid_num",
    //     value: tid.toUpperCase(),
    //   };
    // }
    if (date) {
      find = {
        ...find,
        field: "datetime",
        value: {
          start: {
            date: moment(date[0]).format("yyyy-MM-DD"),
            time: "00:00",
          },
          end: {
            date: moment(date[1]).format("yyyy-MM-DD"),
            time: "23:59:59",
          },
        },
      };
    }
    // if (has == 1) {
    //   find = {
    //     ...find,
    //     field: "plate_chars",
    //     value: "nonull",
    //   };
    // } else if (has == 2) {
    //   find = {
    //     ...find,
    //     field: "plate_chars",
    //     value: null,
    //   };
    // } else {
    if (isEmpty(find))
      find = {
        ...find,
        field: "datetime",
        value: {
          start: {
            date: moment(new Date().setDate(-1)).format("yyyy-MM-DD"),
            time: "00:00",
          },
          end: {
            date: moment(new Date()).format("yyyy-MM-DD"),
            time: "23:59:59",
          },
        },
      };
    // }

    try {
      setloading(true);
      console.time("l");
      const result = await mainApi({
        method: "POST",
        url: url,
        data: {
          ...find,
        },
      });

      setlist(result?.data.rows);
      settotal(result?.data?.count);
      // setpages(result?.data?.pages);
      console.timeEnd("l");
      setloading(false);
    } catch (e) {
      // if (e)

      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  useEffect(() => {
    data != null && fetchCar();
  }, [data]);

  const fetchCar = async () => {
    try {
      setcarloading(true);

      const result = await mainApi({
        url: `/api/v1/rfid/rfidtag/${data?.epc_num}`,
        method: "GET",
      });

      setcar(result.data);
      setcarloading(false);
    } catch (e) {
      // if (e)

      if (e.response?.status == 401) logout();
      setcarloading(false);
    }
  };
  return (
    <div className="flex  p-4 flex-col ">
      <div className="flex flex-row  justify-between items-center">
        <div className="font-semibold text-2xl text-def-text">RFID </div>
        {total > 0 && (
          <div className="font-semibold text-xl ">{`Нийт : ${total}`} </div>
        )}
      </div>
      <div>
        <div className="flex flex-row py-2.5 border-t-2 border-b-2 border-b-def-background mt-4  justify-between">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <div className="flex flex-row px-2.5 items-center mt-3">
                <BsFillCalendarDateFill
                  size={20}
                  color="#E15116"
                  className="ml-0.5"
                />
                <RangePicker
                  bordered={false}
                  placeholder={["Эхлэх огноо", "Дуусах огноо"]}
                  style={{ minWidth: 150 }}
                  onChange={(date, dateString) => {
                    setdate(dateString);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row px-2.5 items-center mt-3">
              <AiOutlineOrderedList size={25} color="#E15116" />
              <Select
                placeholder="Давхцалын төлөв"
                className="w-40"
                bordered={false}
                onChange={(value) => {
                  setunique(value);
                }}
                options={distinct_list}
              />
            </div>
          </div>
          <div
            onClick={() => fetch()}
            className="flex flex-row items-center bg-def-primary rounded p-2 hover:cursor-pointer"
          >
            <div className="text-sm w-24 text-center font-semibold text-white">
              Хайх
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center h-full items-center">
            <img src="../magnify.svg" />
          </div>
        ) : (
          <CustomList
            page={page}
            setpage={setpage}
            total={total}
            list={list}
            columns={unique == 0 ? columns : columns_distinc}
            pages={pages}
            hidePage={false}
            showPage
          />
        )}
      </div>
    </div>
  );
};

export default List;

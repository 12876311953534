import objectPath from "object-path";
import { mainApi } from "../../utils/api";
import { useEffect, useState } from "react";
import { Divider, Spin } from "antd";

const Item = ({ data }) => {
  const epc = objectPath.get(data, "epc_num");
  const tid = objectPath.get(data, "tid_num");
  const date = objectPath.get(data, "data");
  const plate_chars = objectPath.get(data, "plate_chars");
  const time = objectPath.get(data, "time");
  const type_plate_chars = objectPath.get(data, "type_plate_chars");
  const manager = objectPath.get(data, "manager.name");
  const direction = objectPath.get(data, "manager.direction");
  const [car, setcar] = useState(null);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    plate_chars && fetch();
  }, [plate_chars]);
  const fetch = async () => {
    try {
      setloading(true);
      const res = await mainApi({
        url: `api/v1/rfid/rfidtag/${epc}`,
        method: "GET",
      });

      setcar(res.data);
      setloading(false);
    } catch (er) {
      setloading(false);
    }
  };
  return (
    <div className="flex p-2">
      <div className="flex w-full bg-white p-3 rounded shadow">
        <div className="border-2 w-32 h-28 m-4 mt-6 border-black rounded p-6 font-bold">
          <div>
            <div>{plate_chars}</div>
            <div
              className={`font-medium  text-white p-2 text-xs text-center mt-1 rounded ${
                type_plate_chars == "UB" ? "bg-green-500" : "bg-red-500"
              } `}
            >{`${type_plate_chars == "UB" ? "Нийслэл" : "Орон нутаг"}`}</div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col">
            <Divider className="w-full" orientation="left">
              Тээврийн хэрэгсэл
            </Divider>
            {loading ? (
              <Spin />
            ) : (
              <div className="flex">
                <div className="flex flex-col w-1/3 ">
                  <div className="text-sm italic">{`Марк : `}</div>
                  <div className="font-semibold ml-2 -mt-1 text-base">{`${car?.markName?.toUpperCase()}`}</div>
                  <div className="text-sm mt-2 italic">{`Өнгө : `}</div>
                  <div className="font-semibold ml-2 -mt-1 text-base">{`${car?.colorName?.toUpperCase()}`}</div>
                </div>
                <div className="flex flex-col w-1/3">
                  <div className="text-sm italic">{`Модел : `}</div>
                  <div className="font-semibold ml-2 -mt-1 text-base">{`${car?.modelName?.toUpperCase()}`}</div>
                  <div className="text-sm mt-2 italic">{`Үйлдвэрлэсэн он  : `}</div>
                  <div className="font-semibold ml-2 -mt-1 text-base">{`${car?.buildYear?.toUpperCase()}`}</div>
                </div>
                <div className="flex flex-col w-1/3">
                  <div className="text-sm italic">{`Зориулалт : `}</div>
                  <div className="font-semibold ml-2 -mt-1 text-base">{`${car?.purposeName?.toUpperCase()}`}</div>
                  <div className="text-sm mt-2 italic">{`Импортлогдсон : `}</div>
                  <div className="font-semibold ml-2 -mt-1 text-base">{`${car?.importDate?.toUpperCase()}`}</div>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full">
            <Divider className="w-full" orientation="left">
              Таг
            </Divider>
            <div className="flex w-full">
              <div className="flex flex-col w-1/2">
                <div className="text-sm italic">{`EPC : `}</div>
                <div className="font-semibold ml-2 -mt-1 text-sm">{`${epc}`}</div>
                <div className="text-sm mt-2 italic">{`Нэр : `}</div>
                <div className="font-semibold ml-2 -mt-1 text-sm">{`${manager}`}</div>
                <div className="text-sm mt-2 italic">{`Огноо : `}</div>
                <div className="font-semibold ml-2 -mt-1 text-sm">{`${date} ${time}`}</div>
              </div>
              <div className="flex flex-col w-1/2">
                <div className="text-sm italic">{`TID : `}</div>
                <div className="font-semibold ml-2 -mt-1 text-sm">{`${tid}`}</div>
                <div className="text-sm mt-2 italic">{`Чиглэл  : `}</div>
                <div className="font-semibold ml-2 -mt-1 text-sm">{`${direction}`}</div>
              </div>
              {/* <div className="flex flex-col w-1/3">
                  <div className="text-sm italic">{`Зориулалт : `}</div>
                  <div className="font-semibold ml-2 -mt-1 text-base">{`${car?.purposeName.toUpperCase()}`}</div>
                  <div className="text-sm mt-2 italic">{`Импортлогдсон : `}</div>
                  <div className="font-semibold ml-2 -mt-1 text-base">{`${car?.importDate.toUpperCase()}`}</div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Item;

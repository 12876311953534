import objectPath from "object-path";
import React from "react";
import { MdWifi, MdWifiOff } from "react-icons/md";
import { CgSmartphoneRam } from "react-icons/cg";
import { FaTemperatureLow } from "react-icons/fa";
import moment from "moment";
import { FORMAT_DATE } from "../../utils/const";
const item = ({ row }) => {
  const name = objectPath.get(row, "manager.name");
  const direction = objectPath.get(row, "direction");
  const act_ant = objectPath.get(row, "act_ant");
  const ant = act_ant?.split(",");
  const ram = objectPath.get(row, "ram");
  const temper = objectPath.get(row, "temper");
  const district = objectPath.get(row, "district");
  const dev_status = objectPath.get(row, "dev_status");
  const createdAt = objectPath.get(row, "createdAt");
  return (
    <div className="w-72 m-3 rounded p-3 shadow-md hover:cursor-pointer hover:border-def-primary hover:border-2">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <div
            className={`h-2 w-2 rounded-full mx-2 mt-1 ${
              dev_status == "ON" ? ` bg-active-text` : ` bg-deactive-text`
            }`}
          />
          <div>
            <div className="text-base font-semibold">{name}</div>
            <div className="text-xs">
              {moment(createdAt).format(FORMAT_DATE)}
            </div>
          </div>
        </div>
      </div>

      <div className="text-sm font-light">{direction}</div>
      <div className="flex flex-row justify-evenly my-2">
        {ant?.map((row, index) => {
          return row == "1" ? (
            <div className="flex flex-col px-1 justify-center items-center">
              <MdWifi size={25} className="text-active-text " />
              <div className="mt-1 text-sm font-semibold">{`ANT - ${
                index + 1
              }`}</div>
            </div>
          ) : (
            <div className="flex flex-col px-1 justify-center items-center">
              <MdWifiOff size={25} className="text-deactive-text" />
              <div className="mt-1 text-sm font-semibold">{`ANT - ${
                index + 1
              }`}</div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center items-center">
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <FaTemperatureLow className="text-red-500" />
            <div className="mx-2">{temper}</div>
          </div>
          <div className="mx-4 bg-def-background" style={{ width: 2 }} />

          <div className="flex flex-row items-center">
            <CgSmartphoneRam />
            <div className="mx-2">{ram}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default item;

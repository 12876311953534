import React from "react";
import Item from "./item";
import { Pagination } from "antd";

const grid = ({ list, total, page, setpage }) => {
  return (
    <div className="flex flex-col p-4">
      <div className="flex justify-center">
        <Pagination
          simple
          defaultCurrent={page}
          onChange={(e) => setpage(e)}
          pageSize={30}
          total={total}
        />
      </div>
      <div className="flex flex-wrap mt-4 justify-center">
        <div className="flex  justify-end items-center my-4"></div>
        {list?.map((row, index) => {
          return <Item key={index} row={row} />;
        })}
      </div>
    </div>
  );
};

export default grid;

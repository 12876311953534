import React, { useState, useEffect } from "react";
import { mainApi } from "../../utils/api";
import { CgArrowsExpandUpRight } from "react-icons/cg";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { Modal, Table } from "antd";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page H",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page F",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page J",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const Bars = () => {
  const [loading, setloading] = useState(false);
  const [list, setlist] = useState(null);
  const [show, setshow] = useState(false);
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    setloading(true);
    // console.log(
    //   "🚀 ~ file: bar.js:107 ~ fetch",
    //   moment(new Date()).format("yyyy-MM-DD")
    // );
    const result = await mainApi({
      url: `/api/v1/graph/manager?limit=10`,
      method: "POST",
      data: {
        field: "datetime",
        value: {
          end: {
            date: moment(new Date()).format("yyyy-MM-DD"),
            time: "23:59:59",
          },
          start: {
            date: moment(new Date()).format("yyyy-MM-DD"),
            time: "00:00:00",
          },
        },
      },
    });

    setlist(result?.data.rows);
    setloading(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex" onClick={() => setshow(true)}>
        <div className="font-bold text-lg">
          Өнөөдөр хамгийн их уншсан 10 төхөөрөмж
        </div>
        <CgArrowsExpandUpRight className="ml-2 mt-1 hover:cursor-pointer" />
      </div>
      <div className="flex p-2">
        <div className="flex bg-white rounded shadow justify-center item-center">
          <BarChart
            width={650}
            height={350}
            data={list}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="manager_name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="UB" name="Нийслэл нийт" stackId="a" fill="#8884d8" />
            <Bar
              dataKey="CS"
              name="Орон нутаг нийт"
              stackId="a"
              fill="#CD5334"
            />
            <Bar
              dataKey="UB_DIS"
              name="Нийслэл давхцалгүй"
              stackId="b"
              fill="#17BEBB"
            />
            <Bar
              dataKey="CS_DIS"
              name="Орон нутаг давхцалгүй"
              stackId="b"
              fill="#EDB88B"
            />
          </BarChart>
        </div>
      </div>
      <Modal
        title="Өнөөдөр хамгийн их уншсан 10 төхөөрөмж"
        width={800}
        open={show}
        onOk={() => setshow(false)}
        onCancel={() => setshow(false)}
      >
        <Table
          dataSource={list}
          pagination={{ position: ["none", "none"] }}
          bordered={true}
          size="middle"
          columns={[
            {
              dataIndex: "manager_name",
              title: "Төхөөрөмжийн нэр",
            },
            {
              dataIndex: "total",
              title: "Нийт",
            },
            {
              dataIndex: "UB",
              title: "Нийслэл",
            },
            {
              dataIndex: "UB_DIS",
              title: "Нийслэл давхцалгүй",
            },
            {
              dataIndex: "CS",
              title: "Орон нутаг",
            },
            {
              dataIndex: "CS_DIS",
              title: "Орон нутаг давхцалгүй",
            },
          ]}
        />
      </Modal>
    </div>
  );
};
export default Bars;

import objectPath from "object-path";
import Line from "./lineCount";
import { PiCpuThin } from "react-icons/pi";
import { RiRfidFill } from "react-icons/ri";
import { MdCalendarMonth } from "react-icons/md";
import { Spin } from "antd";
const Stats = ({ stat }) => {
  const CS_REG = objectPath.get(stat, "CS_REG");
  const tagindb = objectPath.get(stat, "tagindb");
  const CS_CURRENT = objectPath.get(stat, "total.CS-CURRENT-DAY");
  const CS_CURRENT_DIS = objectPath.get(stat, "total.CS-CURRENT-DAY-DISTINC");

  const CS_MONTH = objectPath.get(stat, "total.CS-CURRENT-MONTH");
  const CS_MONTH_DIS = objectPath.get(stat, "total.CS-CURRENT-MONTH-DISTINC");

  return (
    <div className="flex flex-col">
      <div className="font-bold text-lg">Орон нутгийн дугаар</div>
      <div className="flex flex-wrap">
        <Line
          percent={50}
          color={"bg-green-400"}
          icon={<RiRfidFill size={20} />}
          text={
            CS_REG ? (
              ` ${CS_REG?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )}`
            ) : (
              <Spin size="small" />
            )
          }
          t={`Нийт бүртгэгдсэн`}
        />
        <Line
          color={"bg-green-400"}
          icon={<PiCpuThin size={30} />}
          text={
            CS_CURRENT_DIS ? (
              `${CS_CURRENT?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )} / 
              ${CS_CURRENT_DIS?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )}`
            ) : (
              <Spin size="small" />
            )
          }
          t={"Өнөөдөр уншигдсан"}
        />

        <Line
          icon={<MdCalendarMonth size={30} />}
          text={
            CS_MONTH_DIS ? (
              `${CS_MONTH?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )} /  
              ${CS_MONTH_DIS?.toString()?.replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              )}`
            ) : (
              <Spin size="small" />
            )
          }
          t={`Энэ сард уншигдсан`}
        />
      </div>
    </div>
  );
};
export default Stats;

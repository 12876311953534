import React, { useState, useEffect } from "react";
import { Button, Input, Spin, Form, Typography, Select, List } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useAuth } from "../utils/provider";
import { mainApi } from "../utils/api";
const MENU = [
  {
    label: "dashboard",
    value: "dashboard",
  },
  {
    label: "tag",
    value: "tag",
  },
  {
    label: "device",
    value: "device",
  },
  {
    label: "manager",
    value: "manager",
  },
  {
    label: "user",
    value: "user",
  },
  {
    label: "app",
    value: "app",
  },
  {
    label: "map",
    value: "map",
  },
  {
    label: "countered",
    value: "countered",
  },
  {
    label: "managerdata",
    value: "managerdata",
  },
  {
    label: "servicelog",
    value: "servicelog",
  },
  {
    label: "statistic",
    value: "statistic",
  },
  {
    label: "car",
    value: "car",
  },
];
const Index = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState(null);
  const history = useNavigate();
  const { logout } = useAuth();
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    setloading(true);
    try {
      const result = await mainApi({
        url: `/api/v1/users/${id}`,
        method: "GET",
      });
      setdata(result.data);
      setloading(false);
    } catch (err) {
      setloading(false);
    }
  };
  const onFinish = async (data) => {
    setloading(true);
    try {
      let menu = "";
      data?.menu?.map((row, index) => {
        menu += "," + row;
      });

      await mainApi({
        url: `/api/v1/users/${id}`,
        method: "PUT",
        data: {
          last_name: data?.last_name,
          first_name: data?.first_name,
          email: data?.email,
          menu: menu.substr(1, menu.length),
        },
      });
      swal("Мэдэгдэл", "Амжилттай бүртгэгдлээ", "success");
      history(-1);
      setloading(false);
    } catch (e) {
      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  const deletebyID = async () => {
    setloading(true);
    try {
      await mainApi({
        url: `/api/v1/users/${id}`,
        method: "DELETE",
      });
      swal("Мэдэгдэл", "Амжилттай устгагдлаа", "success");
      history(-1);
      setloading(false);
    } catch (e) {
      if (e.response.status == 401) logout();
      setloading(false);
    }
  };
  return (
    <div className="flex flex-col p-2  ">
      <div className="flex justify-between items-center p-3 border-def-primary border-l-8 border-def-blue my-2">
        <div className="text-base font-semibold">Хэрэглэгчийн мэдээлэл</div>
      </div>
      {loading ? (
        <div className="flex justify-center h-full items-center">
          <img src="../../magnify.svg" />
        </div>
      ) : (
        <Form
          layout="vertical"
          initialValues={{
            last_name: data?.last_name,
            first_name: data?.first_name,
            email: data?.email,
            password: data?.password,
            menu: data?.menu?.split(","),
          }}
          onFinish={onFinish}
        >
          <div className="shadow mt-4 p-4">
            <div className=" flex flex-wrap ">
              <div className="w-1/2 p-2">
                <Form.Item label="Эцэг/эх/-ийн нэр" name="last_name">
                  <Input placeholder="Бичих" required />
                </Form.Item>
                <Form.Item label="Нэр" name="first_name">
                  <Input placeholder="Бичих" required />
                </Form.Item>
              </div>
              <div className="w-1/2 p-2">
                <Form.Item label="Имайл" name="email">
                  <Input placeholder="Бичих" required />
                </Form.Item>
                <Form.Item label="Эрх" name="menu">
                  <Select options={MENU} mode="multiple" />
                </Form.Item>
              </div>
            </div>
            <div className="flex  justify-between my-4 items-center">
              <Button
                danger
                icon={<AiOutlineArrowLeft className="ml-2" />}
                size="middle"
                onClick={() => history(-1)}
              />
              <div className="flex flex-row ">
                <Form.Item className="mx-4">
                  <Button
                    type="dashed"
                    size="middle"
                    onClick={deletebyID}
                    loading={loading}
                  >
                    Устгах
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    danger
                    type="primary"
                    size="middle"
                    htmlType="submit"
                    loading={loading}
                  >
                    Хадгалах
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Index;

const list = [
  {
    EPC: "E200001B710901872100A999",
    plateNo: "3563УНУ",
    markName: "Toyota",
    modelName: "Sai",
    purposeName: "Суудал",
    colorName: "Сувдан цагаан",
    typeNameMon: "Ердийн бүхээгтэй",
    buildYear: "2013",
  },
  {
    EPC: "E200001B710901872080A99D",
    plateNo: "3563УНУ",
    markName: "Toyota",
    modelName: "Sai",
    purposeName: "Суудал",
    colorName: "Сувдан цагаан",
    typeNameMon: "Ердийн бүхээгтэй",
    buildYear: "2013",
  },
  {
    EPC: "E200001B710901872070A995",
    plateNo: "3563УНУ",
    markName: "Toyota",
    modelName: "Sai",
    purposeName: "Суудал",
    colorName: "Сувдан цагаан",
    typeNameMon: "Ердийн бүхээгтэй",
    buildYear: "2013",
  },
  // {
  //   EPC: "E200001B710901872080A99D",
  //   plateNo: "9102УБМ",
  //   markName: "Toyota",
  //   modelName: "Prius",
  //   purposeName: "Суудал",
  //   colorName: "Цайвар цэнхэр",
  //   typeNameMon: "Ердийн бүхээгтэй",
  //   buildYear: "2010",
  // },
];
const v_list = [
  {
    EPC: "E200001B710901872100A999",
    isVerify: true,
    speed: 50,
    insurance: true,
    taxes: true,
    violance: [],
  },
  {
    EPC: "E200001B710901872080A99D",
    isVerify: true,
    speed: 38,
    insurance: false,
    taxes: false,
    violance: ["Татвар", "Даатгал"],
  },
  {
    EPC: "E200001B710901872070A995",
    isVerify: false,
    speed: 90,
    insurance: false,
    taxes: false,
    violance: ["Хурд хэтрүүлсэн", "Татвар", "Даатгал"],
  },
  // {
  //   EPC: "E200001B710901872080A99D",
  //   isVerify: true,
  //   speed: 60,
  //   insurance: true,
  //   taxes: false,
  //   violance: ["Татвар"],
  // },
];
const getCarInfo = (epc) => {
  const d = list.filter((row) => row.EPC == epc);
  return d.length > 0 ? d[0] : null;
};
const getViolance = (epc) => {
  const d = v_list.filter((row) => row.EPC == epc);
  return d.length > 0 ? d[0] : null;
};
export { getCarInfo, getViolance };

import { io } from "socket.io-client";
import { BASE_URL, SOCKET_ID } from "./const";

// "undefined" means the URL will be computed from the `window.location` object

export const socket = io(BASE_URL, {
  autoConnect: true,
  transports: ["websocket"],
  auth: {
    uuid: SOCKET_ID,
  },
});

import React from "react";
import { SettingTwoTone, SmileOutlined } from "@ant-design/icons";
const index = () => {
  return (
    <div className="flex  p-4 flex-col">
      <div className="flex flex-row  justify-between ">
        <div className="font-semibold text-2xl text-def-text">Application</div>
      </div>
      <a
        target="_blank"
        href="/ODT_READER_106_data.apk"
        className="my-4 p-4 text-white bg-def-primary rounded hover:cursor-pointer w-64 text-center text-sm"
      >
        RFID татах
      </a>
      <a
        target="_blank"
        href="/police_3.apk"
        className="my-4 p-4 text-white bg-def-primary rounded hover:cursor-pointer w-64 text-center text-sm"
      >
        POLICE татах
      </a>
    </div>
  );
};

export default index;

import React from "react";
import { Pagination } from "antd";

import objectPath from "object-path";

import DataGrid, {
  Column,
  FilterRow,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  Selection,
  Scrolling,
  Summary,
  GroupItem,
  Export,
  hidePage,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";

const table = ({
  list,
  columns,
  total,
  page,
  setpage,
  isDrag,
  showTotal,
  showPage,
  width,
}) => {
  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "DataGrid.xlsx"
        );
      });
    });
    e.cancel = true;
  };
  const customizeColumns = (col) => {
    col?.map((row, index) => {
      const { width } = columns[index];
      col[index].minWidth = width ? width : 100;
    });
  };
  return (
    <div className="mt-4">
      {showTotal && (
        <div className="flex flex-row justify-between">
          <div className="font-bold my-2">{`Нийт : ${total
            ?.toString()
            ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`}</div>
          {showPage && (
            <Pagination
              simple
              defaultCurrent={page}
              onChange={(e) => {
                setpage(e);
              }}
              pageSize={30}
              total={total}
            />
          )}
        </div>
      )}

      <DataGrid
        dataSource={list}
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}
        allowColumnReordering={true}
        onExporting={onExporting}
        repaintChangesOnly={true}
        customizeColumns={customizeColumns}
        width={width ? width : window.innerWidth * 0.9}
        // columnWidth={150}
        // defaultSelectedRowKeys={selectedKeys}
      >
        <Scrolling columnRenderingMode="virtual" />

        <Paging defaultPageSize={30} />
        {isDrag && (
          <GroupPanel
            emptyPanelText={"Бүлэглэх баганыг энд чирч тавина уу."}
            visible={true}
          />
        )}
        <Grouping autoExpandAll={false} />
        {/* <FilterRow visible={true} /> */}

        {columns?.map((row, index) => {
          // const custom = row?.render ? row?.render(row) : false;

          return (
            <Column
              dataField={row?.field}
              fixed={true}
              width={row?.width}
              minWidth={row?.width}
              caption={row?.title}
              cellRender={row?.render}
            />
          );
        })}
        <Summary>
          <GroupItem
            column="Id"
            summaryType="count"
            displayFormat="Нийт : {0}"
          />
        </Summary>
        <Export enabled={true} />
      </DataGrid>
    </div>
  );
};

export default table;

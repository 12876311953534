import Device from "../device/list";
import Tag from "../tag/list";
import SettingList from "../settings/list";
import SettingData from "../settings/data";
import Setting from "../settings";
import Dashboard from "../dashboard";
import User from "../user";
import Map from "../map";
import UserList from "../user/list";
import CounteredList from "../device/countered";
import UserRegister from "../user/register";
import Servicelog from "../pages/Servicelog";
import Statistic from "../pages/Statistic";
import Car from "../pages/Car";

import App from "../app/index";
const routes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "map",
    element: <Map />,
  },
  {
    path: "device",
    element: <Device />,
  },
  {
    path: "countered",
    element: <CounteredList />,
  },
  {
    path: "tag",
    element: <Tag />,
  },
  {
    path: "manager",
    element: <SettingList />,
  },
  {
    path: "managerdata",
    element: <SettingData />,
  },
  {
    path: "manager/:id",
    element: <Setting />,
  },
  {
    path: "user",
    element: <UserList />,
  },
  {
    path: "user/:id",
    element: <User />,
  },
  {
    path: "user/register",
    element: <UserRegister />,
  },

  {
    path: "statistic",
    element: <Statistic />,
  },
  {
    path: "servicelog",
    element: <Servicelog />,
  },
  {
    path: "car",
    element: <Car />,
  },
  {
    path: "app",
    element: <App />,
  },
];

export default routes;
